import React, { useEffect, useState, useRef } from "react";
import MouseFollower from "../components/MouseFollower";
import ConfirmNewPassword from "../components/Modal-Wndows/ConfirmNewPassword";
import { Helmet } from "react-helmet";

const mouseTitle = "Reset Password";
const browserTitle = "Reser Password";

const ResetPassword = () => {
  useEffect(() => {
    document.title = browserTitle; // Set document title on component mount
  }, []); // Empty dependency array to run only once

  const [passwordVisibility, setPasswordVisibility] = useState([false, false]);
  const [isModalOpen, setIsModalOpen] = useState(false); // <-- Add this line

  const togglePasswordVisibility = (index) => {
    setPasswordVisibility((prevVisibility) =>
      prevVisibility.map((visible, i) => (i === index ? !visible : visible))
    );
  };

  const passwordInput = document.querySelectorAll('input[name="password"]');

  const confirmPasswordInput = document.querySelector(
    'input[name="confirm-password"]'
  );

  const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@_#$%]).{8,}$/;

  passwordInput.forEach((input) => {
    if (input.name === "password") {
      // Apply regex only to the password field
      input.addEventListener("input", function () {
        if (!passwordRegex.test(this.value)) {
          this.setCustomValidity(
            "Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, one digit, and one special character (@, _, $, #, or %)."
          );
        } else {
          this.setCustomValidity("");
        }
      });

      input.addEventListener("invalid", function () {
        this.setCustomValidity(
          "Password must have at least 8 characters, contain one uppercase letter, one lowercase letter, one digit, and one special character (@, _, $, #, or %)."
        );
      });
    }
  });

  const passwordRef = useRef(null);
  const confirmPasswordRef = useRef(null);
  const [passwordError, setPasswordError] = useState("");

  const validatePasswords = () => {
    if (passwordRef.current.value !== confirmPasswordRef.current.value) {
      setPasswordError("Passwords do not match!");
    } else {
      setPasswordError("");
    }
  };

  const handlePasswordChange = () => {
    validatePasswords();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (passwordError) return; // Prevent form submission if there's an error

    // Proceed with form submission
    setPasswordError(""); // Clear the error message if passwords match
    // Submit the form or perform other actions
    setIsModalOpen(true); // Open the modal when the form is submitted
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="resetPassword">
      <Helmet>
        <title>Elite Numerology - Sign Up and Login</title>
      </Helmet>
      <div className="signup-and-login">
        <div className="sign-up">
          <h1>Reset Password</h1>
          <form className="sign-up-form" onSubmit={handleSubmit}>
            <label>
              <input
                type={passwordVisibility[0] ? "text" : "password"}
                placeholder="Enter New Password"
                name="password"
                ref={passwordRef}
                required
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="#000000"
                viewBox="0 0 256 256"
                className="eye eye--closed"
                onClick={() => togglePasswordVisibility(0)}
                style={{ display: passwordVisibility[0] ? "none" : "block" }}
              >
                <path d="M228,175a8,8,0,0,1-10.92-3l-19-33.2A123.23,123.23,0,0,1,162,155.46l5.87,35.22a8,8,0,0,1-6.58,9.21A8.4,8.4,0,0,1,160,200a8,8,0,0,1-7.88-6.69l-5.77-34.58a133.06,133.06,0,0,1-36.68,0l-5.77,34.58A8,8,0,0,1,96,200a8.4,8.4,0,0,1-1.32-.11,8,8,0,0,1-6.58-9.21L94,155.46a123.23,123.23,0,0,1-36.06-16.69L39,172A8,8,0,1,1,25.06,164l20-35a153.47,153.47,0,0,1-19.3-20A8,8,0,1,1,38.22,99c16.6,20.54,45.64,45,89.78,45s73.18-24.49,89.78-45A8,8,0,1,1,230.22,109a153.47,153.47,0,0,1-19.3,20l20,35A8,8,0,0,1,228,175Z"></path>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="#000000"
                viewBox="0 0 256 256"
                className="eye eye--open"
                onClick={() => togglePasswordVisibility(0)}
                style={{ display: passwordVisibility[0] ? "block" : "none" }}
              >
                <path d="M247.31,124.76c-.35-.79-8.82-19.58-27.65-38.41C194.57,61.26,162.88,48,128,48S61.43,61.26,36.34,86.35C17.51,105.18,9,124,8.69,124.76a8,8,0,0,0,0,6.5c.35.79,8.82,19.57,27.65,38.4C61.43,194.74,93.12,208,128,208s66.57-13.26,91.66-38.34c18.83-18.83,27.3-37.61,27.65-38.4A8,8,0,0,0,247.31,124.76ZM128,192c-30.78,0-57.67-11.19-79.93-33.25A133.47,133.47,0,0,1,25,128,133.33,133.33,0,0,1,48.07,97.25C70.33,75.19,97.22,64,128,64s57.67,11.19,79.93,33.25A133.46,133.46,0,0,1,231.05,128C223.84,141.46,192.43,192,128,192Zm0-112a48,48,0,1,0,48,48A48.05,48.05,0,0,0,128,80Zm0,80a32,32,0,1,1,32-32A32,32,0,0,1,128,160Z"></path>
              </svg>
              <div>
                {passwordError && (
                  <p className="password-error password-error--reset">
                    {passwordError}
                  </p>
                )}
              </div>
            </label>
            <label>
              <input
                type={passwordVisibility[1] ? "text" : "password"}
                placeholder="Confirm New Password"
                name="confirm-password"
                ref={confirmPasswordRef}
                onChange={handlePasswordChange}
                required
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="#000000"
                viewBox="0 0 256 256"
                className="eye eye--closed"
                onClick={() => togglePasswordVisibility(1)}
                style={{ display: passwordVisibility[1] ? "none" : "block" }}
              >
                <path d="M228,175a8,8,0,0,1-10.92-3l-19-33.2A123.23,123.23,0,0,1,162,155.46l5.87,35.22a8,8,0,0,1-6.58,9.21A8.4,8.4,0,0,1,160,200a8,8,0,0,1-7.88-6.69l-5.77-34.58a133.06,133.06,0,0,1-36.68,0l-5.77,34.58A8,8,0,0,1,96,200a8.4,8.4,0,0,1-1.32-.11,8,8,0,0,1-6.58-9.21L94,155.46a123.23,123.23,0,0,1-36.06-16.69L39,172A8,8,0,1,1,25.06,164l20-35a153.47,153.47,0,0,1-19.3-20A8,8,0,1,1,38.22,99c16.6,20.54,45.64,45,89.78,45s73.18-24.49,89.78-45A8,8,0,1,1,230.22,109a153.47,153.47,0,0,1-19.3,20l20,35A8,8,0,0,1,228,175Z"></path>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="#000000"
                viewBox="0 0 256 256"
                className="eye eye--open"
                onClick={() => togglePasswordVisibility(1)}
                style={{ display: passwordVisibility[1] ? "block" : "none" }}
              >
                <path d="M247.31,124.76c-.35-.79-8.82-19.58-27.65-38.41C194.57,61.26,162.88,48,128,48S61.43,61.26,36.34,86.35C17.51,105.18,9,124,8.69,124.76a8,8,0,0,0,0,6.5c.35.79,8.82,19.57,27.65,38.4C61.43,194.74,93.12,208,128,208s66.57-13.26,91.66-38.34c18.83-18.83,27.3-37.61,27.65-38.4A8,8,0,0,0,247.31,124.76ZM128,192c-30.78,0-57.67-11.19-79.93-33.25A133.47,133.47,0,0,1,25,128,133.33,133.33,0,0,1,48.07,97.25C70.33,75.19,97.22,64,128,64s57.67,11.19,79.93,33.25A133.46,133.46,0,0,1,231.05,128C223.84,141.46,192.43,192,128,192Zm0-112a48,48,0,1,0,48,48A48.05,48.05,0,0,0,128,80Zm0,80a32,32,0,1,1,32-32A32,32,0,0,1,128,160Z"></path>
              </svg>
              <div>
                {passwordError && (
                  <p className="password-error password-error--reset">
                    {passwordError}
                  </p>
                )}
              </div>
            </label>
            <button type="submit">Confirm New Password</button>
          </form>
        </div>
      </div>
      <MouseFollower mouseTitle={mouseTitle} />
      {isModalOpen && <ConfirmNewPassword onClose={closeModal} />}
    </div>
  );
};

export default ResetPassword;
