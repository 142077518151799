import React, { useState, useEffect, useContext } from "react";
import { GlobalStateContext } from "../components/GlobalVariable";
import { jerseyInstructions } from "./Jersey_How_To";
import jerseyChart from "../assets/Jersey-1.png";
import ShareButtons from "../components/ShareButtons";
import DateOfBirthSelector from "../components/DateOfBirthSelector";
import Loader from "../components/Loader";
import MouseFollower from "../components/MouseFollower";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { all_images } from "../components/Related-Tools";
import {
  JERSEY_FAQ_QUESTION_1,
  JERSEY_FAQ_ANSWER_1,
  JERSEY_FAQ_QUESTION_2,
  JERSEY_FAQ_ANSWER_2,
  JERSEY_FAQ_QUESTION_3,
  JERSEY_FAQ_ANSWER_3,
  JERSEY_FAQ_QUESTION_4,
  JERSEY_FAQ_ANSWER_4,
  JERSEY_FAQ_QUESTION_5,
  JERSEY_FAQ_ANSWER_5,
  JERSEY_FAQ_QUESTION_6,
  JERSEY_FAQ_ANSWER_6,
  JERSEY_FAQ_QUESTION_7,
  JERSEY_FAQ_ANSWER_7,
  JERSEY_FAQ_QUESTION_8,
  JERSEY_FAQ_ANSWER_8,
  JERSEY_FAQ_QUESTION_9,
  JERSEY_FAQ_ANSWER_9,
  JERSEY_FAQ_QUESTION_10,
  JERSEY_FAQ_ANSWER_10,
} from "../components/Constants";

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const rawFaqs = [
    { question: JERSEY_FAQ_QUESTION_1, answer: JERSEY_FAQ_ANSWER_1 },
    { question: JERSEY_FAQ_QUESTION_2, answer: JERSEY_FAQ_ANSWER_2 },
    { question: JERSEY_FAQ_QUESTION_3, answer: JERSEY_FAQ_ANSWER_3 },
    { question: JERSEY_FAQ_QUESTION_4, answer: JERSEY_FAQ_ANSWER_4 },
    { question: JERSEY_FAQ_QUESTION_5, answer: JERSEY_FAQ_ANSWER_5 },
    { question: JERSEY_FAQ_QUESTION_6, answer: JERSEY_FAQ_ANSWER_6 },
    { question: JERSEY_FAQ_QUESTION_7, answer: JERSEY_FAQ_ANSWER_7 },
    { question: JERSEY_FAQ_QUESTION_8, answer: JERSEY_FAQ_ANSWER_8 },
    { question: JERSEY_FAQ_QUESTION_9, answer: JERSEY_FAQ_ANSWER_9 },
    { question: JERSEY_FAQ_QUESTION_10, answer: JERSEY_FAQ_ANSWER_10 },
  ];

  const faqs = rawFaqs.filter((faq) => faq.question);

  return (
    <div className="faq-container">
      {faqs.map((faq, index) => (
        <div key={index} className="faq-item">
          <div className="faq-question" onClick={() => toggleFAQ(index)}>
            <span className="arrow">{activeIndex === index ? "-" : "+"}</span>{" "}
            {faq.question}
          </div>
          {activeIndex === index && (
            <div className="faq-answer" onClick={() => toggleFAQ(index)}>
              {faq.answer}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

function Jersey({ userData, setUserData }) {
  const {
    day,
    setDay,
    month,
    setMonth,
    year,
    setYear,
    rateLimitCount,
    setRateLimitCount,
  } = useContext(GlobalStateContext);
  const updateGlobalVariable = () => {
    //e.preventDefault();
    if (rateLimitCount > 0) {
      setRateLimitCount(rateLimitCount - 1);
    }
  };
  const [clicksRemaining, setClicksRemaining] = useState(5);
  const [, setTimerVisible] = useState(false);
  const [, setTimerSeconds] = useState(60);
  const [, setError] = useState("");
  const url = window.location.href;
  const mouseTitle = "What is your lucky jersey number?";
  const browserTitle = "Lucky Jersey Number";
  const [daysInMonth, setDaysInMonth] = useState(31);
  const [jerseyNumber, setJerseyNumber] = useState("");
  const [disclaimer, setDisclaimer] = useState("");
  const [loading, setLoading] = useState(false);
  const hasContent = jerseyNumber !== null;
  const [preferredJerseyNumber, setPreferredJerseyNumber] = useState(
    userData?.preferredJerseyNumber || ""
  );
  const [responseReadyToShow, setResponseReadyToShow] = useState(false);
  const [
    preferredJerseyNumberErrorMessage,
    setPreferredJerseyNumberErrorMessage,
  ] = useState("");

  const shareText = "Find out your lucky jersey number based on numerology at";

  const handleDayChange = (event) => setDay(parseInt(event.target.value, 10));
  const handleMonthChange = (event) =>
    setMonth(parseInt(event.target.value, 10));
  const handleYearChange = (event) => setYear(parseInt(event.target.value, 10));
  const handlePreferredJerseyNumberChange = (event) => {
    const value = event.target.value;
    if (/^\d*$/.test(value)) {
      // Allow only numbers
      setPreferredJerseyNumber(value);
    }
  };

  useEffect(() => {
    // Reset jerseyNumber and responseReadyToShow when day, month, year, or preferredJerseyNumber change
    setJerseyNumber("");
    setResponseReadyToShow(false);
  }, [day, month, year, preferredJerseyNumber]);

  const startTimer = () => {
    setTimerVisible(true);

    // Timer to update seconds every second until the next minute starts
    const timerInterval = setInterval(() => {
      setTimerSeconds((prevSeconds) => {
        if (prevSeconds === 0) {
          clearInterval(timerInterval); // Clear interval when timer reaches 0
          setTimerSeconds(60); // Restart timer from 60 seconds
          setClicksRemaining(5); // Refresh clicks remaining to 5
          startTimer(); // Restart the timer
        }
        return prevSeconds - 1;
      });
    }, 1000);
  };

  const handleSubmit = async () => {
    updateGlobalVariable();
    // Clear previous errors
    setError(null);
    // Prevent submission if clicks remaining are exhausted
    if (clicksRemaining <= 0) {
      setError(
        "You have reached the maximum number of submissions. Please wait for the timer to reset."
      );
      return;
    }
    let hasError = false;

    if (!preferredJerseyNumber.trim()) {
      setPreferredJerseyNumberErrorMessage(
        "Please enter preferred Jersey Number."
      );
      hasError = true;
    } else {
      setPreferredJerseyNumberErrorMessage("");
    }

    if (hasError) return;

    setLoading(true);
    setResponseReadyToShow(false); // Ensure result is hidden initially

    try {
      const response = await fetch(
        "https://api.scanandplaytambola.com/JerseyNumber",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            dateOfBirth: `${day}-${month}-${year}`,
            preffered_Jersy_Number: preferredJerseyNumber,
          }),
        }
      );

      const data = await response.json();

      // Use a timeout to simulate the loader display duration
      setTimeout(() => {
        setJerseyNumber(data.lucky_JerseyNumbers || "");
        setLoading(false);
        setResponseReadyToShow(true);
      }, 5000); // Adjust the delay (2000ms = 2 seconds)
    } catch (error) {
      setLoading(false);
      setResponseReadyToShow(false);
    } finally {
      // Decrement clicks remaining
      setClicksRemaining((prev) => prev - 1);

      // Start timer only on the first submit
      if (clicksRemaining === 5) {
        startTimer();
      }
    }
  };

  useEffect(() => {
    document.title = browserTitle;
  }, []);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true, // Optionally enable/disable arrows if needed
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 840,
        settings: {
          slidesToShow: 1,
          dots: false,
        },
      },
      {
        breakpoint: 485,
        settings: {
          slidesToShow: 1,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  const imageIndexes = [0, 1, 14, 5, 24, 2, 18, 13, 6, 7, 12];

  return (
    <div className="Jersey">
      <Helmet>
        <title>Lucky Jersey Number</title>
        <meta
          name="description"
          content="Discover your lucky jersey number and enhance your sports performance with numerology insights."
        />
      </Helmet>
      <div className="content">
        <div className="input-and-result-window">
          <div className="special">
            <h1>Jersey Number Compatibility</h1>
            <div>
              <DateOfBirthSelector
                day={day}
                month={month}
                year={year}
                daysInMonth={daysInMonth}
                handleDayChange={handleDayChange}
                handleMonthChange={handleMonthChange}
                handleYearChange={handleYearChange}
              />
            </div>
            <div>
              <p className="label">Preferred Jersey Number:</p>
              <input
                type="text"
                value={preferredJerseyNumber}
                onChange={handlePreferredJerseyNumberChange}
                placeholder="Type here..."
              />
              {preferredJerseyNumberErrorMessage && (
                <p style={{ color: "red" }}>
                  {preferredJerseyNumberErrorMessage}
                </p>
              )}
            </div>
            <button
              onClick={handleSubmit}
              disabled={loading || clicksRemaining <= 0}
            >
              Submit
            </button>
          </div>
          <div className="special result-window">
            <h2>Result:</h2>
            {loading && <Loader />}
            {!loading && jerseyNumber && (
              <>
                <p>{jerseyNumber}</p>
              </>
            )}
          </div>
        </div>
        <div
          className="left-align-content"
          id="breadcrumbs"
          style={{ display: "flex", alignItems: "center" }}
        >
          <p className="share-line" style={{ margin: 0 }}>
            Please feel free to share with your Friends and Family :
          </p>
          <ShareButtons url={url} shareText={shareText} />
        </div>
        <div className="left-align-content">
          <h2>Calculate Your Lucky Jersey Number</h2>
          <div
            dangerouslySetInnerHTML={{
              __html: jerseyInstructions.howToCalculate,
            }}
          />
          <img
            src={jerseyChart}
            alt="Jersey Compatibility Chart"
            className="center-image-jersey"
            width="800"
          />
        </div>
        <div className="related-tools-div">
          <h1 className="h1-center">Our Other Tools</h1>
          <div className="carousel-container">
            <Slider {...settings}>
              {imageIndexes.map((index) => (
                <div key={index}>
                  <Link to={all_images[index].link}>
                    <img
                      src={all_images[index].imageSrc}
                      alt={all_images[index].alt}
                      className="carousel-image"
                    />
                  </Link>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        <div className="sharebuttons">
          <ShareButtons url={url} shareText={shareText} />
        </div>
        <MouseFollower mouseTitle={mouseTitle} />
        <div className="faq-section">
          <h2 className="faq-heading">Frequently Asked Questions</h2>
          <Faq />
        </div>
      </div>
    </div>
  );
}

export default Jersey;
