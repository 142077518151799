import React, { useEffect, useState } from "react";
import MouseFollower from "../components/MouseFollower";
import { Helmet } from "react-helmet";
import DeleteAccount from "../components/Modal-Wndows/DeleteAccount"; // Import the modal
import { Link } from "react-router-dom";

// const mouseTitle = "Terms & Conditions";
const browserTitle = "Delete Account Verification";

const DeleteAccountVerify = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  const handleYesClick = () => {
    setModalOpen(true); // Open the modal
  };

  const handleCloseModal = () => {
    setModalOpen(false); // Close the modal
  };

  useEffect(() => {
    document.title = browserTitle; // Set document title on component mount
  }, []); // Empty dependency array to run only once

  return (
    <div className="tandc">
      <Helmet>
        <title>Delete Account Verification</title>
      </Helmet>
      <h2 className="h2-center">
        Are you sure you want to delete your User Account and related details
        from our system?
      </h2>
      <div className="yes-or-no">
        <button className="yes-button" onClick={handleYesClick}>
          Yes
        </button>
        <Link className="forgot-password" to="/my-profile">
          No, take me back to My Profile
        </Link>
      </div>
      {/* <MouseFollower mouseTitle={mouseTitle} /> */}
      <DeleteAccount isOpen={isModalOpen} onClose={handleCloseModal} />{" "}
      {/* Modal */}
    </div>
  );
};

export default DeleteAccountVerify;
