import React, { useEffect, useState } from "react";
import MouseFollower from "../components/MouseFollower";
import { Helmet } from "react-helmet";
import PersonalDetails from "../components/Modal-Wndows/PersonalDetails";

// const mouseTitle = "My Orders";
const browserTitle = "My Orders";

const ServicesPurchased = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    document.title = browserTitle; // Set document title on component mount
  }, []); // Empty dependency array to run only once

  const handleOpenModal = (e) => {
    e.preventDefault(); // Prevent link default behavior
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="ServicesPurchased">
      <Helmet>
        <title>Services Purchased</title>
      </Helmet>
      <div className="credit-balance">
        <h1>Services Purchased</h1>
        <div className="transaction-history">
          <table>
            <thead>
              <tr>
                <th>Order ID</th>
                <th>Service Name</th>
                <th>Tokens Used</th>
                <th>Balance Tokens</th>
                <th>Date</th>
                <th>Customer Request</th>
              </tr>
            </thead>
            <tbody>
              {/* Example rows */}
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <p>
                    <a
                      className="complete-your-details"
                      href="#"
                      onClick={handleOpenModal}
                    >
                      Complete your details
                    </a>
                  </p>
                </td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {/* <MouseFollower mouseTitle={mouseTitle} /> */}
      <PersonalDetails isOpen={isModalOpen} onClose={handleCloseModal} />
    </div>
  );
};

export default ServicesPurchased;
