import React, { useState, useEffect, useContext } from "react";
import { GlobalStateContext } from "../components/GlobalVariable";
import "./MobileNumber.css";
import { mobileNumberInstructions } from "./MobileNumber_How_To";
import ShareButtons from "../components/ShareButtons";
import Loader from "../components/Loader";
import MouseFollower from "../components/MouseFollower";
import { Helmet } from "react-helmet";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { all_images } from "../components/Related-Tools";
import {
  MOBILE_FAQ_QUESTION_1,
  MOBILE_FAQ_ANSWER_1,
  MOBILE_FAQ_QUESTION_2,
  MOBILE_FAQ_ANSWER_2,
  MOBILE_FAQ_QUESTION_3,
  MOBILE_FAQ_ANSWER_3,
  MOBILE_FAQ_QUESTION_4,
  MOBILE_FAQ_ANSWER_4,
  MOBILE_FAQ_QUESTION_5,
  MOBILE_FAQ_ANSWER_5,
  MOBILE_FAQ_QUESTION_6,
  MOBILE_FAQ_ANSWER_6,
  MOBILE_FAQ_QUESTION_7,
  MOBILE_FAQ_ANSWER_7,
  MOBILE_FAQ_QUESTION_8,
  MOBILE_FAQ_ANSWER_8,
  MOBILE_FAQ_QUESTION_9,
  MOBILE_FAQ_ANSWER_9,
  MOBILE_FAQ_QUESTION_10,
  MOBILE_FAQ_ANSWER_10,
} from "../components/Constants";

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const rawFaqs = [
    { question: MOBILE_FAQ_QUESTION_1, answer: MOBILE_FAQ_ANSWER_1 },
    { question: MOBILE_FAQ_QUESTION_2, answer: MOBILE_FAQ_ANSWER_2 },
    { question: MOBILE_FAQ_QUESTION_3, answer: MOBILE_FAQ_ANSWER_3 },
    { question: MOBILE_FAQ_QUESTION_4, answer: MOBILE_FAQ_ANSWER_4 },
    { question: MOBILE_FAQ_QUESTION_5, answer: MOBILE_FAQ_ANSWER_5 },
    { question: MOBILE_FAQ_QUESTION_6, answer: MOBILE_FAQ_ANSWER_6 },
    { question: MOBILE_FAQ_QUESTION_7, answer: MOBILE_FAQ_ANSWER_7 },
    { question: MOBILE_FAQ_QUESTION_8, answer: MOBILE_FAQ_ANSWER_8 },
    { question: MOBILE_FAQ_QUESTION_9, answer: MOBILE_FAQ_ANSWER_9 },
    { question: MOBILE_FAQ_QUESTION_10, answer: MOBILE_FAQ_ANSWER_10 },
  ];

  const faqs = rawFaqs.filter((faq) => faq.question);

  return (
    <div className="faq-container">
      {faqs.map((faq, index) => (
        <div key={index} className="faq-item">
          <div className="faq-question" onClick={() => toggleFAQ(index)}>
            <span className="arrow">{activeIndex === index ? "-" : "+"}</span>{" "}
            {faq.question}
          </div>
          {activeIndex === index && (
            <div className="faq-answer">{faq.answer}</div>
          )}
        </div>
      ))}
    </div>
  );
};

function MobileNumber({ userData, setUserData }) {
  const { rateLimitCount, setRateLimitCount } = useContext(GlobalStateContext);
  const updateGlobalVariable = () => {
    //e.preventDefault();
    if (rateLimitCount > 0) {
      setRateLimitCount(rateLimitCount - 1);
    }
  };
  const [clicksRemaining, setClicksRemaining] = useState(5);
  const [, setTimerVisible] = useState(false);
  const [, setTimerSeconds] = useState(60);
  const [, setError] = useState("");
  const [inputMobileNumber, setInputMobileNumber] = useState("");
  const url = window.location.href;
  const mouseTitle = "Is your Mobile number Lucky for you?";
  const browserTitle = "Lucky Mobile Number";
  const [mobileNumber, setMobileNumber] = useState("");
  const [apiResponse, setApiResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const hasContent = mobileNumber !== null;

  // State for error messages
  const [mobileNumberError, setMobileNumberError] = useState("");

  const shareText =
    "I found this amazing tool to check if my Mobile number is lucky for me, and it also tells me about remedies in case it is not. Find out about your's at ";

  const handleMobileNumberChange = (value, country, event) => {
    // Allow the plus sign at the start and digits afterwards
    if (/^\+?\d*$/.test(value)) {
      setMobileNumber(value);
    }
  };

  const startTimer = () => {
    setTimerVisible(true);

    // Timer to update seconds every second until the next minute starts
    const timerInterval = setInterval(() => {
      setTimerSeconds((prevSeconds) => {
        if (prevSeconds === 0) {
          clearInterval(timerInterval); // Clear interval when timer reaches 0
          setTimerSeconds(60); // Restart timer from 60 seconds
          setClicksRemaining(5); // Refresh clicks remaining to 5
          startTimer(); // Restart the timer
        }
        return prevSeconds - 1;
      });
    }, 1000);
  };

  const handleSubmit = async () => {
    updateGlobalVariable();
    // Clear previous errors
    setError(null);
    // Prevent submission if clicks remaining are exhausted
    if (clicksRemaining <= 0) {
      setError(
        "You have reached the maximum number of submissions. Please wait for the timer to reset."
      );
      return;
    }
    // Clear previous error messages
    setMobileNumberError("");

    // Validate inputs
    if (!inputMobileNumber.trim()) {
      setMobileNumberError("Please enter Mobile Number.");
      return;
    }

    setLoading(true);

    const minimumLoadingTime = 5000; // Minimum loader display time in milliseconds

    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.scanandplaytambola.com//mobile",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ mobileNumber: inputMobileNumber }), // Use inputMobileNumber here
          }
        );
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error fetching data from API", error);
        return { error: "Error fetching data from API" };
      } finally {
        // Decrement clicks remaining
        setClicksRemaining((prev) => prev - 1);

        // Start timer only on the first submit
        if (clicksRemaining === 5) {
          startTimer();
        }
      }
    };

    const timer = setTimeout(() => {
      fetchData().then((data) => {
        setApiResponse(data);
        setLoading(false);
      });
    }, minimumLoadingTime);
  };

  useEffect(() => {
    document.title = browserTitle;
  }, []);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true, // Optionally enable/disable arrows if needed
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 840,
        settings: {
          slidesToShow: 1,
          dots: false,
        },
      },
      {
        breakpoint: 485,
        settings: {
          slidesToShow: 1,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  const imageIndexes = [0, 1, 8, 15, 3, 2, 4, 5, 6, 14, 12];

  return (
    <div className="MobileNumber">
      <Helmet>
        <title>Lucky Mobile Number</title>
        <meta
          name="description"
          content="Find your Lucky Mobile Number to enhance communication, attract prosperity, and maintain positive connections."
        />
      </Helmet>
      {loading && <Loader />}
      <div className="content">
        <br />
        <div className="input-and-result-window">
          <div className="special">
            <h1>Lucky Mobile Number Calculator?</h1>
            <div>
              <p className="label">Enter your Mobile number:</p>
              <div className="mobile-input">
                <div className="country-flag-dropdown">
                  <PhoneInput
                    country={"in"}
                    value={mobileNumber}
                    onChange={handleMobileNumberChange}
                    inputProps={{
                      name: "phoneNumber",
                      required: true,
                    }}
                  />
                </div>
                <div className="mobile-number-input">
                  <input
                    className="main-mobile-number"
                    type="text"
                    placeholder="Enter Mobile Number"
                    value={inputMobileNumber}
                    onChange={(e) => {
                      const value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                      setInputMobileNumber(value);
                    }}
                    name="mobileNumber"
                    required
                  />
                </div>
              </div>
              {mobileNumberError && (
                <p className="error-message">{mobileNumberError}</p>
              )}
            </div>

            <button
              onClick={handleSubmit}
              disabled={loading || clicksRemaining <= 0}
            >
              Submit
            </button>
          </div>
          <div className="special result-window">
            <h2>Result</h2>
            {loading && <Loader />}
            <p>
              Overall Vibration Number :{" "}
              <b>{apiResponse ? apiResponse.overallVibrationNumber : ""}</b>
            </p>
            <p>
              Primary Influence Number :{" "}
              <b>
                {apiResponse
                  ? apiResponse.primaryInfluenceNumber.join(", ")
                  : ""}
              </b>
            </p>
            {!loading && apiResponse && (
              <p>
                <em>For more details, see below...</em>
              </p>
            )}
          </div>
        </div>

        <div></div>

        {/* API Response Section */}
        {!loading && apiResponse && (
          <div
            className={`left-align-content result-ui ${
              hasContent ? "filled" : ""
            }`}
            id="breadcrumbs"
          >
            {apiResponse.error ? (
              <p>{apiResponse.error}</p>
            ) : (
              <div>
                <h2 className="h2-center">Interpretation</h2>
                <p>
                  <b>Overall Vibration Interpretation:</b>{" "}
                  {apiResponse.overallVibrationInterPretation}
                </p>
                <p>
                  <b>Primary Influence Interpretation:</b>{" "}
                  {apiResponse.primaryInfluenceNumber_Interpretation.join(", ")}
                </p>
              </div>
            )}
          </div>
        )}
        <div
          className="left-align-content"
          id="breadcrumbs"
          style={{ display: "flex", alignItems: "center" }}
        >
          <p className="share-line" style={{ margin: 0 }}>
            Please feel free to share with your Friends and Family :
          </p>
          <ShareButtons url={url} shareText={shareText} />
        </div>
        <div className="left-align-content">
          <h2>Mobile Number Calculation</h2>
          <div
            dangerouslySetInnerHTML={{
              __html: mobileNumberInstructions.howToCalculate,
            }}
          />
        </div>
        <div className="related-tools-div">
          <h1 className="h1-center">Our Other Tools</h1>
          <div className="carousel-container">
            <Slider {...settings}>
              {imageIndexes.map((index) => (
                <div key={index}>
                  <Link to={all_images[index].link}>
                    <img
                      src={all_images[index].imageSrc}
                      alt={all_images[index].alt}
                      className="carousel-image"
                    />
                  </Link>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        <div className="sharebuttons">
          <ShareButtons url={url} shareText={shareText} />
        </div>
        <MouseFollower title={mouseTitle} url={url} />
        <div className="faq-section">
          <h2 className="faq-heading">Frequently Asked Questions</h2>
          <Faq />
        </div>
      </div>
    </div>
  );
}

export default MobileNumber;
