import React, { useState } from "react";
import "./ModalWindows.css"; // Import CSS for styling
import ThanksTokenPurchased from "./ThanksTokenPurchased";

const PurchaseTokens = ({ onClose }) => {
  const [selectedPlan, setSelectedPlan] = useState("");
  const [showThanksModal, setShowThanksModal] = useState(false);

  const plans = [
    { id: "plan1", name: "PLAN 1", description: "Description for Plan 1" },
    { id: "plan2", name: "PLAN 2", description: "Description for Plan 2" },
    { id: "plan3", name: "PLAN 3", description: "Description for Plan 3" },
  ];

  const handleSelectPlan = (planId) => {
    setSelectedPlan(planId);
  };

  const handleBuyNow = () => {
    setShowThanksModal(true); // Show the ThanksTokenPurchased modal
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <h1>Choose a Token Plan</h1>
        <h3>Tokens are used to purchase personalized services</h3>
        <div className="service-container purchase-service-container">
          {plans.map((plan) => (
            <div
              key={plan.id}
              className={`service ${
                selectedPlan === plan.id ? "selected" : ""
              }`}
              onClick={() => handleSelectPlan(plan.id)}
            >
              <div className="radio-and-title">
                <input
                  className="purchase-token"
                  type="radio"
                  name="tokenPlan"
                  checked={selectedPlan === plan.id}
                  onChange={() => handleSelectPlan(plan.id)}
                  onClick={(e) => e.stopPropagation()}
                />
                <h2>{plan.name}</h2>
              </div>
              <p>{plan.description}</p>
            </div>
          ))}
        </div>
        <button onClick={handleBuyNow}>Buy Now</button>
        <br></br>
        <button className="close-modal" onClick={onClose}>
          Close
        </button>
      </div>
      {showThanksModal && (
        <ThanksTokenPurchased onClose={() => setShowThanksModal(false)} />
      )}
    </div>
  );
};

export default PurchaseTokens;
