import React, { useEffect, useState } from "react";
import MouseFollower from "../components/MouseFollower";
import { Helmet } from "react-helmet";
import PurchaseTokens from "../components/Modal-Wndows/PurchaseTokens";

const mouseTitle = "My Credit Balance";
const browserTitle = "My Credit Balance";

const CreditBalance = () => {
  useEffect(() => {
    document.title = browserTitle; // Set document title on component mount
  }, []); // Empty dependency array to run only once

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handlePurchaseClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="CreditBalance">
      <Helmet>
        <title>My Credit Balance</title>
      </Helmet>
      <div className="credit-balance">
        <h1>My Credit Balance</h1>
        <div className="balance-and-tokens">
          <h2>Elite Tokens:</h2>
          <button className="buy-tokens" onClick={handlePurchaseClick}>
            Purchase Tokens
          </button>
        </div>
        <h3>Transaction History</h3>
        <div className="transaction-history">
          <table>
            <thead>
              <tr>
                <th className="s-no">S.No</th>
                <th>Date</th>
                <th>Product Name</th>
                <th>Transaction ID</th>
                <th>Type</th>
                <th>Amount</th>
                <th>Payment Status</th>
                <th>Current Tokens</th>
                <th>Tokens Received</th>
                <th>Submit Details</th>
                <th>Tokens After Transaction</th>
              </tr>
            </thead>
            <tbody>
              {/* Example rows */}
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {isModalOpen && <PurchaseTokens onClose={handleCloseModal} />}
      <MouseFollower mouseTitle={mouseTitle} />
    </div>
  );
};

export default CreditBalance;
