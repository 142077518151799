import React, { useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Header2.css";
import logo1 from "../assets/logo1.png";

function Header2() {
  const menuBtnRef = useRef(null);
  const dropdownMenuRef = useRef(null);
  const nestedDropdownRef = useRef(null);
  const nestedDropdownCompatibilityRef = useRef(null);
  const location = useLocation();
  const isFooterPage =
    location.pathname === "/" ||
    location.pathname === "/about-us" ||
    location.pathname === "/contact-us" ||
    location.pathname === "/terms-and-conditions" ||
    location.pathname === "/faq";

  useEffect(() => {
    const menuBtn = menuBtnRef.current;
    const dropdownMenu = dropdownMenuRef.current;
    const nestedDropdown = nestedDropdownRef.current;
    const nestedDropdownCompatibility = nestedDropdownCompatibilityRef.current;

    const handleMenuClick = () => {
      menuBtn.classList.toggle("is-active");
      dropdownMenu.classList.toggle("show");
      if (!menuBtn.classList.contains("is-active")) {
        nestedDropdown.classList.remove("show");
        nestedDropdownCompatibility.classList.remove("show");
        document.body.classList.remove("no-scroll"); // Remove no-scroll class from body
      } else {
        document.body.classList.add("no-scroll"); // Add no-scroll class to body
      }
    };

    const handleNumerologyClick = (e) => {
      e.preventDefault();
      dropdownMenu.classList.remove("show");
      nestedDropdownCompatibility.classList.remove("show");
      nestedDropdown.classList.toggle("show");
    };

    const handleCompatibilityClick = (e) => {
      e.preventDefault();
      dropdownMenu.classList.remove("show");
      nestedDropdown.classList.remove("show");
      nestedDropdownCompatibility.classList.toggle("show");
    };

    const handleOutsideClick = (e) => {
      if (
        (nestedDropdown.classList.contains("show") &&
          !nestedDropdown.contains(e.target) &&
          !e.target.classList.contains("numerology-link")) ||
        (nestedDropdownCompatibility.classList.contains("show") &&
          !nestedDropdownCompatibility.contains(e.target) &&
          !e.target.classList.contains("compatibility-link"))
      ) {
        nestedDropdown.classList.remove("show");
        nestedDropdownCompatibility.classList.remove("show");
        menuBtn.classList.add("is-active");
        dropdownMenu.classList.add("show");
        document.body.classList.remove("no-scroll"); // Remove no-scroll class from body
      }
    };

    if (menuBtn) {
      menuBtn.addEventListener("click", handleMenuClick);
    }

    const numerologyLink = document.querySelector(".numerology-link");
    const compatibilityLink = document.querySelector(".compatibility-link");
    if (numerologyLink) {
      numerologyLink.addEventListener("click", handleNumerologyClick);
    }
    if (compatibilityLink) {
      compatibilityLink.addEventListener("click", handleCompatibilityClick);
    }

    document.addEventListener("click", handleOutsideClick);

    return () => {
      if (menuBtn) {
        menuBtn.removeEventListener("click", handleMenuClick);
      }
      if (numerologyLink) {
        numerologyLink.removeEventListener("click", handleNumerologyClick);
      }
      if (compatibilityLink) {
        compatibilityLink.removeEventListener(
          "click",
          handleCompatibilityClick
        );
      }
      document.removeEventListener("click", handleOutsideClick);
      document.body.classList.remove("no-scroll"); // Remove no-scroll class from body on component unmount
    };
  }, []);

  return (
    <header className="header2">
      <div className="header2-content">
        <Link to="/" className="header2-logo-and-h1">
          <figure className="header2-nav-logo">
            <img className="header2-logo-image" src={logo1} alt="Logo" />
          </figure>
        </Link>
        <div>
          <button name="hamburgerMenu" ref={menuBtnRef} className="hamburger">
            <div className="bar"></div>
          </button>
          <p className="hamburger-label">Menu</p>
        </div>
      </div>
      <nav ref={dropdownMenuRef} className="dropdown-menu">
        <ul className="header2-dropdown1">
          <li>
            <Link
              to="/"
              onClick={() => {
                menuBtnRef.current.classList.remove("is-active");
                dropdownMenuRef.current.classList.remove("show");
                nestedDropdownRef.current.classList.remove("show");
                nestedDropdownCompatibilityRef.current.classList.remove("show");
                document.body.classList.remove("no-scroll"); // Remove no-scroll class from body
              }}
            >
              Home
            </Link>
          </li>
          <li>
            <Link to="/about" className="numerology-link">
              Numerology
            </Link>
          </li>
          <li>
            <Link to="/services" className="compatibility-link">
              Compatibility
            </Link>
          </li>
          <li>
            <a
              href="https://api.whatsapp.com/send?phone=9910335637&text=I%20want%20to%20book%20a%20numerology%20consultation"
              className="header2-chat-now"
              target="_blank"
              rel="noopener noreferrer"
            >
              Chat Now
            </a>
          </li>
          <li></li>
        </ul>
      </nav>

      <nav ref={nestedDropdownRef} className="nested-dropdown">
        <ul>
          <li>
            <a
              className="header2-go-back-numerology"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                nestedDropdownRef.current.classList.remove("show");
                dropdownMenuRef.current.classList.add("show");
              }}
            >
              Previous Menu
            </a>
          </li>
          <li>
            <Link
              to="/balance-number-calculator"
              onClick={() => {
                menuBtnRef.current.classList.remove("is-active");
                dropdownMenuRef.current.classList.remove("show");
                nestedDropdownRef.current.classList.remove("show");
                document.body.classList.remove("no-scroll");
              }}
            >
              Balance Number
            </Link>
          </li>
          <li>
            <Link
              to="/is-your-bank-account-number-lucky"
              onClick={() => {
                menuBtnRef.current.classList.remove("is-active");
                dropdownMenuRef.current.classList.remove("show");
                nestedDropdownRef.current.classList.remove("show");
              }}
            >
              Bank Account Number
            </Link>
          </li>
          <li>
            <Link
              to="/personal-fast-day-calculator-numerology"
              onClick={() => {
                menuBtnRef.current.classList.remove("is-active");
                dropdownMenuRef.current.classList.remove("show");
                nestedDropdownRef.current.classList.remove("show");
              }}
            >
              Best Day to Fast
            </Link>
          </li>

          <li>
            <Link
              to="/lucky-business-name-calculator"
              onClick={() => {
                menuBtnRef.current.classList.remove("is-active");
                dropdownMenuRef.current.classList.remove("show");
                nestedDropdownRef.current.classList.remove("show");
              }}
            >
              Business Name
            </Link>
          </li>

          <li>
            <Link
              to="/daily-numerological-chart"
              onClick={() => {
                menuBtnRef.current.classList.remove("is-active");
                dropdownMenuRef.current.classList.remove("show");
                nestedDropdownRef.current.classList.remove("show");
              }}
            >
              Daily Biorhythm Chart
            </Link>
          </li>
          <li>
            <Link
              to="/destiny-number-calculator"
              onClick={() => {
                menuBtnRef.current.classList.remove("is-active");
                dropdownMenuRef.current.classList.remove("show");
                nestedDropdownRef.current.classList.remove("show");
              }}
            >
              Destiny Number
            </Link>
          </li>
          <li>
            <Link
              to="/hidden-passion-number-calculator"
              onClick={() => {
                menuBtnRef.current.classList.remove("is-active");
                dropdownMenuRef.current.classList.remove("show");
                nestedDropdownRef.current.classList.remove("show");
              }}
            >
              Hidden Passion Number
            </Link>
          </li>
          <li>
            <Link
              to="/is-your-house-number-lucky-with-remedies"
              onClick={() => {
                menuBtnRef.current.classList.remove("is-active");
                dropdownMenuRef.current.classList.remove("show");
                nestedDropdownRef.current.classList.remove("show");
              }}
            >
              House Number
            </Link>
          </li>
          <li>
            <Link
              to="/jersey-number-calculator"
              onClick={() => {
                menuBtnRef.current.classList.remove("is-active");
                dropdownMenuRef.current.classList.remove("show");
                nestedDropdownRef.current.classList.remove("show");
                document.body.classList.remove("no-scroll");
              }}
            >
              Jersey Compatibility
            </Link>
          </li>
          <li>
            <Link
              to="/what-are-karmic-debt-numbers-in-numerology"
              onClick={() => {
                menuBtnRef.current.classList.remove("is-active");
                dropdownMenuRef.current.classList.remove("show");
                nestedDropdownRef.current.classList.remove("show");
              }}
            >
              Karmic Debt Number
            </Link>
          </li>
          <li>
            <Link
              to="/kua-number-calculator"
              onClick={() => {
                menuBtnRef.current.classList.remove("is-active");
                dropdownMenuRef.current.classList.remove("show");
                nestedDropdownRef.current.classList.remove("show");
              }}
            >
              Kua Number
            </Link>
          </li>
          <li>
            <Link
              to="/life-path-number-calculator"
              onClick={() => {
                menuBtnRef.current.classList.remove("is-active");
                dropdownMenuRef.current.classList.remove("show");
                nestedDropdownRef.current.classList.remove("show");
              }}
            >
              Life Path Number
            </Link>
          </li>
          <li>
            <Link
              to="/create-your-lo-shu-grid-missing-number-remedies"
              onClick={() => {
                menuBtnRef.current.classList.remove("is-active");
                dropdownMenuRef.current.classList.remove("show");
                nestedDropdownRef.current.classList.remove("show");
              }}
            >
              Lo Shu Grid
            </Link>
          </li>
          <li>
            <Link
              to="/numerology-maturity-number-calculator"
              onClick={() => {
                menuBtnRef.current.classList.remove("is-active");
                dropdownMenuRef.current.classList.remove("show");
                nestedDropdownRef.current.classList.remove("show");
              }}
            >
              Maturity Number
            </Link>
          </li>
          <li>
            <Link
              to="/is-your-mobile-number-lucky"
              onClick={() => {
                menuBtnRef.current.classList.remove("is-active");
                dropdownMenuRef.current.classList.remove("show");
                nestedDropdownRef.current.classList.remove("show");
              }}
            >
              Mobile Number
            </Link>
          </li>
          <li>
            <Link
              to="/personalized-monthly-biorhythm-chart"
              onClick={() => {
                menuBtnRef.current.classList.remove("is-active");
                dropdownMenuRef.current.classList.remove("show");
                nestedDropdownRef.current.classList.remove("show");
              }}
            >
              Monthly Biorhythm Chart
            </Link>
          </li>
          <li>
            <Link
              to="/name-number-calculator"
              onClick={() => {
                menuBtnRef.current.classList.remove("is-active");
                dropdownMenuRef.current.classList.remove("show");
                nestedDropdownRef.current.classList.remove("show");
              }}
            >
              Name Number
            </Link>
          </li>
          <li>
            <Link
              to="/numerology-personal-year-calculator"
              onClick={() => {
                menuBtnRef.current.classList.remove("is-active");
                dropdownMenuRef.current.classList.remove("show");
                nestedDropdownRef.current.classList.remove("show");
              }}
            >
              Personal Year Number
            </Link>
          </li>
          <li>
            <Link
              to="/personality-number-calculator"
              onClick={() => {
                menuBtnRef.current.classList.remove("is-active");
                dropdownMenuRef.current.classList.remove("show");
                nestedDropdownRef.current.classList.remove("show");
              }}
            >
              Personality Number
            </Link>
          </li>
          <li>
            <Link
              to="/pinnacle-number-calculator"
              onClick={() => {
                menuBtnRef.current.classList.remove("is-active");
                dropdownMenuRef.current.classList.remove("show");
                nestedDropdownRef.current.classList.remove("show");
              }}
            >
              Pinnacle Cycle
            </Link>
          </li>

          <li>
            <Link
              to="/soul-urge-number-calculator"
              onClick={() => {
                menuBtnRef.current.classList.remove("is-active");
                dropdownMenuRef.current.classList.remove("show");
                nestedDropdownRef.current.classList.remove("show");
              }}
            >
              Soul Urge Number
            </Link>
          </li>
          <li>
            <Link
              to="/subconscious-self-number-calculator"
              onClick={() => {
                menuBtnRef.current.classList.remove("is-active");
                dropdownMenuRef.current.classList.remove("show");
                nestedDropdownRef.current.classList.remove("show");
              }}
            >
              Subconscious Self Number
            </Link>
          </li>
          <li>
            <Link
              to="/is-your-vehicle-number-lucky-with-remedies"
              onClick={() => {
                menuBtnRef.current.classList.remove("is-active");
                dropdownMenuRef.current.classList.remove("show");
                nestedDropdownRef.current.classList.remove("show");
              }}
            >
              Vehicle Number
            </Link>
          </li>
        </ul>
      </nav>

      <nav ref={nestedDropdownCompatibilityRef} className="nested-dropdown">
        <ul>
          <li>
            <a
              className="header2-go-back-compatibility"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                nestedDropdownCompatibilityRef.current.classList.remove("show");
                dropdownMenuRef.current.classList.add("show");
              }}
            >
              Previous Menu
            </a>
          </li>
          <li>
            <Link
              to="/numerology-partner-compatibility"
              onClick={() => {
                menuBtnRef.current.classList.remove("is-active");
                dropdownMenuRef.current.classList.remove("show");
                nestedDropdownCompatibilityRef.current.classList.remove("show");
                document.body.classList.remove("no-scroll"); // Remove no-scroll class from body
              }}
            >
              Partner Compatibility
            </Link>
          </li>
          <li>
            <Link
              to="/numerology-kua-compatibility"
              onClick={() => {
                menuBtnRef.current.classList.remove("is-active");
                dropdownMenuRef.current.classList.remove("show");
                nestedDropdownCompatibilityRef.current.classList.remove("show");
                document.body.classList.remove("no-scroll"); // Remove no-scroll class from body
              }}
            >
              Kua Number Compatibility
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header2;
