import React, { useEffect, useState, useRef, useContext } from "react";
import MouseFollower from "../components/MouseFollower";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { GlobalStateContext } from "../components/GlobalVariable";

// const mouseTitle = "Sign Up and Login";
const browserTitle = "Sign Up";

const SignUp = () => {
  const { setCsrfToken } = useContext(GlobalStateContext);

  useEffect(() => {
    // Function to fetch CSRF token from the API
    const fetchCsrfToken = async () => {
      try {
        const response = await fetch(
          "https://orderprocess.elitenumerology.com/api/get-csrf-token",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          const csrfToken = data.csrf_token;
          console.log("CSRF Token:", csrfToken);
          // Set the CSRF token in global state
          setCsrfToken(csrfToken);
        } else {
          console.error("Failed to fetch CSRF token:", response.status);
        }
      } catch (error) {
        console.error("Error fetching CSRF token:", error);
      }
    };

    fetchCsrfToken();
  }, [setCsrfToken]);

  useEffect(() => {
    document.title = browserTitle; // Set document title on component mount

    const emailInputs = document.querySelectorAll('input[type="email"]');

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    emailInputs.forEach((input) => {
      input.addEventListener("input", function () {
        if (!emailRegex.test(this.value)) {
          this.setCustomValidity("Please enter a valid email id");
        } else {
          this.setCustomValidity("");
        }
      });
      input.addEventListener("invalid", function () {
        this.setCustomValidity("Please enter a valid email id");
      });
    });

    const passwordInput = document.querySelectorAll('input[name="password"]');

    const confirmPasswordInput = document.querySelector(
      'input[name="confirm-password"]'
    );

    const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@_#$%]).{8,}$/;

    passwordInput.forEach((input) => {
      if (input.name === "password") {
        // Apply regex only to the password field
        input.addEventListener("input", function () {
          if (!passwordRegex.test(this.value)) {
            this.setCustomValidity(
              "Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, one digit, and one special character (@, _, $, #, or %)."
            );
          } else {
            this.setCustomValidity("");
          }
        });

        input.addEventListener("invalid", function () {
          this.setCustomValidity(
            "Password must have at least 8 characters, contain one uppercase letter, one lowercase letter, one digit, and one special character (@, _, $, #, or %)."
          );
        });
      }
    });

    const mobileInput = document.querySelector('input[name="mobile"]');
    mobileInput.addEventListener("input", function () {
      this.value = this.value.replace(/[^0-9]/g, ""); // Replace non-numeric characters with an empty string
    });
  }, []); // Empty dependency array to run only once

  const [passwordVisibility, setPasswordVisibility] = useState([
    false,
    false,
    false,
  ]);

  const [selectedPlan, setSelectedPlan] = useState("");

  const plans = [
    { id: "plan1", name: "PLAN 1", description: "Description for Plan 1" },
    { id: "plan2", name: "PLAN 2", description: "Description for Plan 2" },
    { id: "plan3", name: "PLAN 3", description: "Description for Plan 3" },
  ];

  const togglePasswordVisibility = (index) => {
    setPasswordVisibility((prevVisibility) =>
      prevVisibility.map((visible, i) => (i === index ? !visible : visible))
    );
  };

  const handleSelectPlan = (planId) => {
    setSelectedPlan(planId);
  };

  const passwordRef = useRef(null);
  const confirmPasswordRef = useRef(null);
  const [passwordError, setPasswordError] = useState("");

  const validatePasswords = () => {
    if (passwordRef.current.value !== confirmPasswordRef.current.value) {
      setPasswordError("Passwords do not match!");
    } else {
      setPasswordError("");
    }
  };

  const handlePasswordChange = () => {
    validatePasswords();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (passwordError) return; // Prevent form submission if there's an error

    // Get form data
    const form = event.target;
    const name = form.name.value;
    const email = form.email.value;
    const password = form.password.value;
    const mobileNumber = form.mobile.value;

    // Ensure the CSRF token is available
    if (!setCsrfToken) {
      setPasswordError("CSRF token is missing.");
      return;
    }

    // Construct the API payload
    const payload = {
      name,
      email,
      password,
      mobile_number: mobileNumber,
      csrf_token: setCsrfToken, // Use the CSRF token from global state
    };

    try {
      const response = await fetch(
        "https://orderprocess.elitenumerology.com/api/register",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("Sign-up successful:", data);
        // Handle successful response, such as redirecting or showing a success message
      } else {
        const errorData = await response.json();
        console.error("Sign-up failed:", errorData);
        // Handle the error response
      }
    } catch (error) {
      console.error("Error during sign-up:", error);
    }
  };

  return (
    <div className="signupandlogin">
      <Helmet>
        <title>Elite Numerology - Sign Up and Login</title>
      </Helmet>
      <div className="signup-and-login">
        <div className="sign-up" id="account-section">
          <h2>Login</h2>
          <form className="sign-up-form">
            <label>
              <span className="signup-span">Email:</span>
              <input type="email" name="email" required />
            </label>
            <label>
              <span className="signup-span">Password:</span>
              <input
                type={passwordVisibility[0] ? "text" : "password"}
                name="password"
                id="password-input"
                required
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="#000000"
                viewBox="0 0 256 256"
                className="eye eye--closed eye--sign-up"
                onClick={() => togglePasswordVisibility(0)}
                style={{ display: passwordVisibility[0] ? "none" : "block" }}
              >
                <path d="M228,175a8,8,0,0,1-10.92-3l-19-33.2A123.23,123.23,0,0,1,162,155.46l5.87,35.22a8,8,0,0,1-6.58,9.21A8.4,8.4,0,0,1,160,200a8,8,0,0,1-7.88-6.69l-5.77-34.58a133.06,133.06,0,0,1-36.68,0l-5.77,34.58A8,8,0,0,1,96,200a8.4,8.4,0,0,1-1.32-.11,8,8,0,0,1-6.58-9.21L94,155.46a123.23,123.23,0,0,1-36.06-16.69L39,172A8,8,0,1,1,25.06,164l20-35a153.47,153.47,0,0,1-19.3-20A8,8,0,1,1,38.22,99c16.6,20.54,45.64,45,89.78,45s73.18-24.49,89.78-45A8,8,0,1,1,230.22,109a153.47,153.47,0,0,1-19.3,20l20,35A8,8,0,0,1,228,175Z"></path>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="#000000"
                viewBox="0 0 256 256"
                className="eye eye--open eye--sign-up"
                onClick={() => togglePasswordVisibility(0)}
                style={{ display: passwordVisibility[0] ? "block" : "none" }}
              >
                <path d="M247.31,124.76c-.35-.79-8.82-19.58-27.65-38.41C194.57,61.26,162.88,48,128,48S61.43,61.26,36.34,86.35C17.51,105.18,9,124,8.69,124.76a8,8,0,0,0,0,6.5c.35.79,8.82,19.57,27.65,38.4C61.43,194.74,93.12,208,128,208s66.57-13.26,91.66-38.34c18.83-18.83,27.3-37.61,27.65-38.4A8,8,0,0,0,247.31,124.76ZM128,192c-30.78,0-57.67-11.19-79.93-33.25A133.47,133.47,0,0,1,25,128,133.33,133.33,0,0,1,48.07,97.25C70.33,75.19,97.22,64,128,64s57.67,11.19,79.93,33.25A133.46,133.46,0,0,1,231.05,128C223.84,141.46,192.43,192,128,192Zm0-112a48,48,0,1,0,48,48A48.05,48.05,0,0,0,128,80Zm0,80a32,32,0,1,1,32-32A32,32,0,0,1,128,160Z"></path>
              </svg>
            </label>

            <button type="submit">Login</button>
            <Link className="forgot-password" to="/forgot-password">
              Forgot Password?
            </Link>
          </form>
        </div>
        <div className="or" id="account-section">
          <h1>OR</h1>
        </div>
        <div className="sign-up">
          <h2>Create an Account</h2>
          <form className="sign-up-form" onSubmit={handleSubmit}>
            <label>
              <input type="text" name="name" placeholder="Name" required />
            </label>
            <label>
              <input type="email" name="email" placeholder="Email" required />
            </label>
            <label>
              <input
                type="tel"
                name="mobile"
                placeholder="Mobile Number"
                required
              />
            </label>
            <label>
              <input
                type={passwordVisibility[1] ? "text" : "password"}
                name="password"
                id="password-input"
                placeholder="Password"
                ref={passwordRef}
                required
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="#000000"
                viewBox="0 0 256 256"
                className="eye eye--open eye--sign-up"
                onClick={() => togglePasswordVisibility(1)}
                style={{ display: passwordVisibility[1] ? "none" : "block" }}
              >
                <path d="M228,175a8,8,0,0,1-10.92-3l-19-33.2A123.23,123.23,0,0,1,162,155.46l5.87,35.22a8,8,0,0,1-6.58,9.21A8.4,8.4,0,0,1,160,200a8,8,0,0,1-7.88-6.69l-5.77-34.58a133.06,133.06,0,0,1-36.68,0l-5.77,34.58A8,8,0,0,1,96,200a8.4,8.4,0,0,1-1.32-.11,8,8,0,0,1-6.58-9.21L94,155.46a123.23,123.23,0,0,1-36.06-16.69L39,172A8,8,0,1,1,25.06,164l20-35a153.47,153.47,0,0,1-19.3-20A8,8,0,1,1,38.22,99c16.6,20.54,45.64,45,89.78,45s73.18-24.49,89.78-45A8,8,0,1,1,230.22,109a153.47,153.47,0,0,1-19.3,20l20,35A8,8,0,0,1,228,175Z"></path>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="#000000"
                viewBox="0 0 256 256"
                className="eye eye--open eye--sign-up"
                onClick={() => togglePasswordVisibility(1)}
                style={{ display: passwordVisibility[1] ? "block" : "none" }}
              >
                <path d="M247.31,124.76c-.35-.79-8.82-19.58-27.65-38.41C194.57,61.26,162.88,48,128,48S61.43,61.26,36.34,86.35C17.51,105.18,9,124,8.69,124.76a8,8,0,0,0,0,6.5c.35.79,8.82,19.57,27.65,38.4C61.43,194.74,93.12,208,128,208s66.57-13.26,91.66-38.34c18.83-18.83,27.3-37.61,27.65-38.4A8,8,0,0,0,247.31,124.76ZM128,192c-30.78,0-57.67-11.19-79.93-33.25A133.47,133.47,0,0,1,25,128,133.33,133.33,0,0,1,48.07,97.25C70.33,75.19,97.22,64,128,64s57.67,11.19,79.93,33.25A133.46,133.46,0,0,1,231.05,128C223.84,141.46,192.43,192,128,192Zm0-112a48,48,0,1,0,48,48A48.05,48.05,0,0,0,128,80Zm0,80a32,32,0,1,1,32-32A32,32,0,0,1,128,160Z"></path>
              </svg>
              <div>
                {passwordError && (
                  <p className="password-error">{passwordError}</p>
                )}
              </div>
            </label>
            <label>
              <input
                type={passwordVisibility[2] ? "text" : "password"}
                name="confirm-password"
                placeholder="Re-enter Password"
                id="password-input"
                required
                ref={confirmPasswordRef}
                onChange={handlePasswordChange}
              />

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="#000000"
                viewBox="0 0 256 256"
                className="eye eye--open eye--sign-up"
                onClick={() => togglePasswordVisibility(2)}
                style={{ display: passwordVisibility[2] ? "none" : "block" }}
              >
                <path d="M228,175a8,8,0,0,1-10.92-3l-19-33.2A123.23,123.23,0,0,1,162,155.46l5.87,35.22a8,8,0,0,1-6.58,9.21A8.4,8.4,0,0,1,160,200a8,8,0,0,1-7.88-6.69l-5.77-34.58a133.06,133.06,0,0,1-36.68,0l-5.77,34.58A8,8,0,0,1,96,200a8.4,8.4,0,0,1-1.32-.11,8,8,0,0,1-6.58-9.21L94,155.46a123.23,123.23,0,0,1-36.06-16.69L39,172A8,8,0,1,1,25.06,164l20-35a153.47,153.47,0,0,1-19.3-20A8,8,0,1,1,38.22,99c16.6,20.54,45.64,45,89.78,45s73.18-24.49,89.78-45A8,8,0,1,1,230.22,109a153.47,153.47,0,0,1-19.3,20l20,35A8,8,0,0,1,228,175Z"></path>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="#000000"
                viewBox="0 0 256 256"
                className="eye eye--open eye--sign-up"
                onClick={() => togglePasswordVisibility(2)}
                style={{ display: passwordVisibility[2] ? "block" : "none" }}
              >
                <path d="M247.31,124.76c-.35-.79-8.82-19.58-27.65-38.41C194.57,61.26,162.88,48,128,48S61.43,61.26,36.34,86.35C17.51,105.18,9,124,8.69,124.76a8,8,0,0,0,0,6.5c.35.79,8.82,19.57,27.65,38.4C61.43,194.74,93.12,208,128,208s66.57-13.26,91.66-38.34c18.83-18.83,27.3-37.61,27.65-38.4A8,8,0,0,0,247.31,124.76ZM128,192c-30.78,0-57.67-11.19-79.93-33.25A133.47,133.47,0,0,1,25,128,133.33,133.33,0,0,1,48.07,97.25C70.33,75.19,97.22,64,128,64s57.67,11.19,79.93,33.25A133.46,133.46,0,0,1,231.05,128C223.84,141.46,192.43,192,128,192Zm0-112a48,48,0,1,0,48,48A48.05,48.05,0,0,0,128,80Zm0,80a32,32,0,1,1,32-32A32,32,0,0,1,128,160Z"></path>
              </svg>
              <div>
                {passwordError && (
                  <p className="password-error">{passwordError}</p>
                )}
              </div>
            </label>

            <button className="hidden" type="submit">
              Sign Up
            </button>
            <div className="signup-conditions">
              <input
                type="checkbox"
                id="termsCheckbox"
                name="termsCheckbox"
                required
              />
              <label for="termsCheckbox">
                <p className="small">
                  I have read and accepted{" "}
                  <Link to="#" target="_blank">
                    Terms and Conditions
                  </Link>{" "}
                  and{" "}
                  <Link to="#" target="_blank">
                    Privacy Policy
                  </Link>
                </p>
              </label>
            </div>
            <button className="sign-up-btn" type="submit">
              Sign Up
            </button>
          </form>
        </div>
      </div>
      {/* <MouseFollower mouseTitle={mouseTitle} /> */}
    </div>
  );
};

export default SignUp;
