import React, { useEffect } from "react";
import MouseFollower from "../components/MouseFollower";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const mouseTitle = "Services Page";
const browserTitle = "Services Page";

const ServicesPage = () => {
  useEffect(() => {
    document.title = browserTitle; // Set document title on component mount
  }, []); // Empty dependency array to run only once

  return (
    <div className="ServicesPage">
      <Helmet>
        <title>Elite Numberology - Services</title>
      </Helmet>
      <div className="service-container">
        <div className="services">
          <h2>SERVICE</h2>
          <Link className="buy-now" to="/purchase-service">
            ORDER NOW
          </Link>
        </div>
        <div className="services">
          <h2>SERVICE</h2>
          <Link className="buy-now" to="/purchase-service">
            ORDER NOW
          </Link>
        </div>
        <div className="services">
          <h2>SERVICE</h2>
          <Link className="buy-now" to="/purchase-service">
            ORDER NOW
          </Link>
        </div>
        <div className="services">
          <h2>SERVICE</h2>
          <Link className="buy-now" to="/purchase-service">
            ORDER NOW
          </Link>
        </div>
        <div className="services">
          <h2>SERVICE</h2>
          <Link className="buy-now" to="/purchase-service">
            ORDER NOW
          </Link>
        </div>
        <div className="services">
          <h2>SERVICE</h2>
          <Link className="buy-now" to="/purchase-service">
            ORDER NOW
          </Link>
        </div>
        <div className="services">
          <h2>SERVICE</h2>
          <Link className="buy-now" to="/purchase-service">
            ORDER NOW
          </Link>
        </div>
        <div className="services">
          <h2>SERVICE</h2>
          <Link className="buy-now" to="/purchase-service">
            ORDER NOW
          </Link>
        </div>
      </div>
      <MouseFollower mouseTitle={mouseTitle} />
    </div>
  );
};

export default ServicesPage;
