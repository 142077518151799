import balance from "../assets/Elite numerology Illustration with text_Balance number.png";
import bank from "../assets/Elite numerology Illustration with text_Bank Account Number.png";
import daily from "../assets/Elite numerology Illustration with text_Daily Biorhythm.png";
import destiny from "../assets/Elite numerology Illustration with text_Destiny Number.png";
import fasting from "../assets/Elite numerology Illustration with text_Fasting Day.png";
import passion from "../assets/Elite numerology Illustration with text_Hidden Passion.png";
import house from "../assets/Elite numerology Illustration with text_House Number.png";
import jersey from "../assets/Elite numerology Illustration with text_Jersey.png";
import karmic from "../assets/Elite numerology Illustration with text_Karmic Debt.png";
import kuacompat from "../assets/Elite numerology Illustration with text_Kua Compatibility.png";
import kua from "../assets/Elite numerology Illustration with text_Kua Number.png";
import lifepath from "../assets/Elite numerology Illustration with text_Life Path Number.png";
import loshu from "../assets/Elite numerology Illustration with text_Lo Shu Grid.png";
import business from "../assets/Elite numerology Illustration with text_Lucky Business name.png";
import maturity from "../assets/Elite numerology Illustration with text_Maturity Number.png";
import mobile from "../assets/Elite numerology Illustration with text_Mobile Number.png";
import monthly from "../assets/Elite numerology Illustration with text_Monthly Biorhythm.png";
import name from "../assets/Elite numerology Illustration with text_Name Number.png";
import partner from "../assets/Elite numerology Illustration with text_Partner Compatibility.png";
import personalyear from "../assets/Elite numerology Illustration with text_Personal Year.png";
import personality from "../assets/Elite numerology Illustration with text_Personality Number.png";
import pinnacle from "../assets/Elite numerology Illustration with text_Pinnacle Number.png";
import soulurge from "../assets/Elite numerology Illustration with text_Soul Urge.png";
import subconscious from "../assets/Elite numerology Illustration with text_Subconscious.png";
import vehicle from "../assets/Elite numerology Illustration with text_Vehicle Number.png";

const all_images = [
  {
    imageSrc: name, // 0
    link: "/name-number-calculator",
    alt: "Numerology Name Number Calculator Tool to Find Your Name Number",
  },
  {
    imageSrc: loshu, // 1
    link: "/create-your-lo-shu-grid-missing-number-remedies",
    alt: "Numerology Calculator easily create Lo Shu Grid with Missing Number Remedies",
  },
  {
    imageSrc: balance, // 2
    link: "/balance-number-calculator",
    alt: "Numerology Calculator to find your Balance Number",
  },
  {
    imageSrc: bank, // 3
    link: "/is-your-bank-account-number-lucky",
    alt: "Find if your Bank account Number is Lucky for you?",
  },
  {
    imageSrc: daily, // 4
    link: "/daily-numerological-chart",
    alt: "Numerology Calculator for Daily Numerological Chart",
  },
  {
    imageSrc: destiny, // 5
    link: "/destiny-number-calculator",
    alt: "Numerology Destiny Number Calculator Tool",
  },
  {
    imageSrc: fasting, // 6
    link: "/personal-fast-day-calculator-numerology",
    alt: "Numerology Personal Fast Day Calculator Tool",
  },
  {
    imageSrc: passion, // 7
    link: "/hidden-passion-number-calculator",
    alt: "Numerology Hidden Passion Number Calculator Tool",
  },
  {
    imageSrc: house, // 8
    link: "/is-your-house-number-lucky-with-remedies",
    alt: "Find if your House Number is Lucky with Remedies",
  },
  {
    imageSrc: jersey, // 9
    link: "/jersey-number-calculator",
    alt: "Numerology Jersey Number Calculator Tool",
  },
  {
    imageSrc: karmic, // 10
    link: "/what-are-karmic-debt-numbers-in-numerology",
    alt: "Learn about Karmic Debt Numbers in Numerology",
  },
  {
    imageSrc: kuacompat, // 11
    link: "/numerology-kua-compatibility",
    alt: "Numerology Kua Compatibility Calculator Tool",
  },
  {
    imageSrc: kua, // 12
    link: "/kua-number-calculator",
    alt: "Numerology Kua Number Calculator Tool",
  },
  {
    imageSrc: lifepath, // 13
    link: "/life-path-number-calculator",
    alt: "Numerology Life Path Number Calculator Tool",
  },
  {
    imageSrc: business, // 14
    link: "/lucky-business-name-calculator",
    alt: "Numerology Lucky Business Name Calculator Tool",
  },
  {
    imageSrc: maturity, // 15
    link: "/numerology-maturity-number-calculator",
    alt: "Numerology Maturity Number Calculator Tool",
  },
  {
    imageSrc: mobile, // 16
    link: "/is-your-mobile-number-lucky",
    alt: "Find if your Mobile Number is Lucky for you?",
  },
  {
    imageSrc: monthly, // 17
    link: "/personalized-monthly-biorhythm-chart",
    alt: "Personalized Monthly Biorhythm Chart in Numerology",
  },
  {
    imageSrc: partner, // 18
    link: "/numerology-partner-compatibility",
    alt: "Numerology Partner Compatibility Calculator Tool",
  },
  {
    imageSrc: personalyear, // 19
    link: "/numerology-personal-year-calculator",
    alt: "Numerology Personal Year Calculator Tool",
  },
  {
    imageSrc: personality, // 20
    link: "/personality-number-calculator",
    alt: "Numerology Personality Number Calculator Tool",
  },
  {
    imageSrc: pinnacle, // 21
    link: "/pinnacle-number-calculator",
    alt: "Numerology Pinnacle Number Calculator Tool",
  },
  {
    imageSrc: soulurge, // 22
    link: "/soul-urge-number-calculator",
    alt: "Numerology Soul Urge Number Calculator Tool",
  },
  {
    imageSrc: subconscious, // 23
    link: "/subconscious-self-number-calculator",
    alt: "Numerology Subconscious Self Number Calculator Tool",
  },
  {
    imageSrc: vehicle, // 24
    link: "/is-your-vehicle-number-lucky-with-remedies",
    alt: "Find if your Vehicle Number is Lucky with Remedies",
  },
];

export { all_images };
