// components/ModalWindows/ThanksTokenPurchased.js
import React from "react";
import "./ModalWindows.css"; // Import CSS for styling
import { Link } from "react-router-dom";

const ThanksTokenPurchased = ({ onClose }) => {
  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <h1>Thank you for purchasing Elite Tokens</h1>
        <p>Your Credit Balance has been updated.</p>
        <p>
          Check out our{" "}
          <Link className="forgot-password" to="/services-page">
            PERSONALISED SERVICES
          </Link>
          page.
        </p>
        <button className="close-modal" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default ThanksTokenPurchased;
