import React, { useState } from "react";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import "./ContactUs.css";
import { Helmet } from "react-helmet";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobileNumberNumber: "",
    message: "",
  });
  const [mobileNumber, setMobileNumber] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [inputMobileNumber, setInputMobileNumber] = useState("");
  const [submissionMessage, setSubmissionMessage] = useState("");
  const handleMobileNumberChange = (value, country, event) => {
    // Allow the plus sign at the start and digits afterwards
    if (/^\+?\d*$/.test(value)) {
      setMobileNumber(value);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await fetch(
        "https://api.scanandplaytambola.com/ContactUS",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      console.log("Form submitted successfully:", data);

      setTimeout(() => {
        setSubmissionMessage(
          "Thank you for contacting us. Our team will get back to you shortly."
        );
        setIsSubmitting(false);
        // Optionally, reset the form here if needed
        setFormData({
          name: "",
          email: "",
          Number: "",
          message: "",
        });
      }, 5000);
    } catch (error) {
      console.error("Error submitting form:", error);
      setIsSubmitting(false);
      // Optionally, show an error message to the user here
    }
  };

  return (
    <div className="contact-us">
      <Helmet>
        <title>Contact Us at Elite Numerology</title>
        <meta
          name="description"
          content="Contact us at Elite Numerology. We would love to hear from you."
        />
      </Helmet>
      <h1 className="c-us-h1">Contact Us</h1>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label className="c-us-label">Name:</label>
          <input
            className="c-us-input1"
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label className="c-us-label">Email ID:</label>
          <input
            className="c-us-input1"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label className="c-us-label">Phone Number:</label>
          <div className="country-flag-dropdown">
            <PhoneInput
              country={"in"}
              value={mobileNumber}
              onChange={handleMobileNumberChange}
              inputProps={{
                name: "phoneNumber",
                required: true,
              }}
            />
          </div>
          <div className="mobile-number-input">
            <input
              className="main-mobile-number1"
              type="text"
              placeholder="Enter Mobile Number"
              value={formData.mobileNumber}
              onChange={(e) => {
                const value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                setInputMobileNumber(value);
              }}
              name="mobileNumber"
              required
            />
          </div>
        </div>
        <div className="form-group-query">
          <label className="c-us-label">Query:</label>
          <textarea
            placeholder="Please type your questions here..."
            className="c-us-textarea"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit" className="c-us-submit" disabled={isSubmitting}>
          {isSubmitting ? "Submitting..." : "Submit"}
        </button>
        {submissionMessage && (
          <div className="submission-message">{submissionMessage}</div>
        )}
      </form>
    </div>
  );
};

export default ContactUs;
