import React, { useEffect, useState, useContext } from "react";
import MouseFollower from "../components/MouseFollower";
import { Helmet } from "react-helmet";
import PurchaseTokens from "../components/Modal-Wndows/PurchaseTokens";
import { Link } from "react-router-dom";
import { GlobalStateContext } from "../components/GlobalVariable";

const mouseTitle = "My Profile";
const browserTitle = "My Profile";

const MyProfile = () => {
  const { csrfToken } = useContext(GlobalStateContext);
  const [isEditing, setIsEditing] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("1234567890");
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    // Set document title on component mount
    document.title = browserTitle;

    // Replace URL state after the component mounts
  }, []); // Empty dependency array to run only once

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const handleInputChange = (e) => {
    setMobileNumber(e.target.value);
  };

  const handleSaveClick = () => {
    setIsEditing(false); // Exit editing mode
    // Optionally, you can add code to save the updated mobile number
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handlePurchaseClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="CreditBalance">
      <Helmet>
        <title>My Profile</title>
      </Helmet>
      <div className="credit-balance">
        <h1>My Profile</h1>
        <p>CSRF Token: {csrfToken}</p>
      </div>

      <MouseFollower mouseTitle={mouseTitle} />

      <div className="profile-container">
        <div className="profile-info-1">
          <div className="profile-item">
            <label>Name:</label>
            <p>John Doe</p> {/* Replace with actual name */}
          </div>
          <div className="profile-item">
            <label>Email:</label>
            <p>john.doe@example.com</p> {/* Replace with actual email */}
          </div>
          <div className="profile-item">
            <label>Mobile Number:</label>
            {isEditing ? (
              <>
                <input
                  type="text"
                  value={mobileNumber}
                  onChange={handleInputChange}
                />
                <button
                  className="profile-mobile-button"
                  onClick={handleSaveClick}
                >
                  Save
                </button>
              </>
            ) : (
              <>
                <p>{mobileNumber}</p>
                <button
                  className="profile-mobile-button"
                  onClick={handleEditClick}
                >
                  Edit
                </button>
              </>
            )}
          </div>
          <div className="profile-item">
            <label>Change Password:</label>
            <p>
              <Link to="/reset-password">Click Here</Link>
            </p>
          </div>
        </div>
        <div className="profile-info-2">
          <a className="forgot-password">Elite Tokens:</a>
          <br></br>
          <br></br>
          <button className="buy-tokens" onClick={handlePurchaseClick}>
            Purchase Tokens
          </button>
          <br></br>
          <br></br>
          <Link to="/credit-balance" className="forgot-password">
            My Credit Balance
          </Link>
          <br></br>
          <br></br>
          <Link to="/services-purchased" className="forgot-password">
            Service History
          </Link>
          <br></br>
          <br></br>
          <Link
            to="/delete-account-login"
            className="forgot-password delete-account"
          >
            Delete User Account
          </Link>
        </div>
      </div>
      {isModalOpen && <PurchaseTokens onClose={handleCloseModal} />}
    </div>
  );
};

export default MyProfile;
