import React, { useState, useEffect, useContext } from "react";
import { GlobalStateContext } from "../components/GlobalVariable";
import { nameNumberInstructions } from "./NameNumber_How_To";
import chaldeanChartImage from "../assets/Chaldean-1.png";
import "./NameNumber.css";
import ShareButtons from "../components/ShareButtons";
import Loader from "../components/Loader";
import MouseFollower from "../components/MouseFollower";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { all_images } from "../components/Related-Tools";
import {
  NAME_FAQ_QUESTION_1,
  NAME_FAQ_ANSWER_1,
  NAME_FAQ_QUESTION_2,
  NAME_FAQ_ANSWER_2,
  NAME_FAQ_QUESTION_3,
  NAME_FAQ_ANSWER_3,
  NAME_FAQ_QUESTION_4,
  NAME_FAQ_ANSWER_4,
  NAME_FAQ_QUESTION_5,
  NAME_FAQ_ANSWER_5,
  NAME_FAQ_QUESTION_6,
  NAME_FAQ_ANSWER_6,
  NAME_FAQ_QUESTION_7,
  NAME_FAQ_ANSWER_7,
  NAME_FAQ_QUESTION_8,
  NAME_FAQ_ANSWER_8,
  NAME_FAQ_QUESTION_9,
  NAME_FAQ_ANSWER_9,
  NAME_FAQ_QUESTION_10,
  NAME_FAQ_ANSWER_10,
} from "../components/Constants";
import exampleTextsNameNumber from "./NameNumber_Example_Text";
import ExampleUI from "../components/ExampleUI";

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const rawFaqs = [
    { question: NAME_FAQ_QUESTION_1, answer: NAME_FAQ_ANSWER_1 },
    { question: NAME_FAQ_QUESTION_2, answer: NAME_FAQ_ANSWER_2 },
    { question: NAME_FAQ_QUESTION_3, answer: NAME_FAQ_ANSWER_3 },
    { question: NAME_FAQ_QUESTION_4, answer: NAME_FAQ_ANSWER_4 },
    { question: NAME_FAQ_QUESTION_5, answer: NAME_FAQ_ANSWER_5 },
    { question: NAME_FAQ_QUESTION_6, answer: NAME_FAQ_ANSWER_6 },
    { question: NAME_FAQ_QUESTION_7, answer: NAME_FAQ_ANSWER_7 },
    { question: NAME_FAQ_QUESTION_8, answer: NAME_FAQ_ANSWER_8 },
    { question: NAME_FAQ_QUESTION_9, answer: NAME_FAQ_ANSWER_9 },
    { question: NAME_FAQ_QUESTION_10, answer: NAME_FAQ_ANSWER_10 },
  ];

  const faqs = rawFaqs.filter((faq) => faq.question);

  return (
    <div className="faq-container">
      {faqs.map((faq, index) => (
        <div key={index} className="faq-item">
          <div className="faq-question" onClick={() => toggleFAQ(index)}>
            <span className="arrow">{activeIndex === index ? "-" : "+"}</span>{" "}
            {faq.question}
          </div>
          {activeIndex === index && (
            <div className="faq-answer" onClick={() => toggleFAQ(index)}>
              {faq.answer}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

function NameNumber({ userData, setUserData }) {
  const { rateLimitCount, setRateLimitCount, fullName, setFullName } =
    useContext(GlobalStateContext);
  const updateGlobalVariable = () => {
    //e.preventDefault();
    if (rateLimitCount > 0) {
      setRateLimitCount(rateLimitCount - 1);
    }
  };
  const [clicksRemaining, setClicksRemaining] = useState(5);
  const [, setTimerVisible] = useState(false);
  const [, setTimerSeconds] = useState(60);
  const [, setError] = useState("");
  const [apiResponse, setApiResponse] = useState(null);
  const shareText =
    "There is so much data hidden in your name and how it impacts you. I just checked mine at";
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null); // State for error message
  const [responseReadyToShow, setResponseReadyToShow] = useState(false);
  const url = window.location.href;
  const mouseTitle = "Find your Name Number";
  const browserTitle = "Name Number";
  const hasContent = apiResponse !== null;

  const handleFullNameChange = (event) => {
    setFullName(event.target.value);
    setApiResponse(null); // Clear previous API result
  };

  const startTimer = () => {
    setTimerVisible(true);

    // Timer to update seconds every second until the next minute starts
    const timerInterval = setInterval(() => {
      setTimerSeconds((prevSeconds) => {
        if (prevSeconds === 0) {
          clearInterval(timerInterval); // Clear interval when timer reaches 0
          setTimerSeconds(60); // Restart timer from 60 seconds
          setClicksRemaining(5); // Refresh clicks remaining to 5
          startTimer(); // Restart the timer
        }
        return prevSeconds - 1;
      });
    }, 1000);
  };

  const handleSubmit = async () => {
    updateGlobalVariable();
    // Clear previous errors
    setError(null);
    // Prevent submission if clicks remaining are exhausted
    if (clicksRemaining <= 0) {
      setError(
        "You have reached the maximum number of submissions. Please wait for the timer to reset."
      );
      return;
    }
    if (!fullName) {
      setErrorMessage("Please enter your Full Name");
      return;
    }

    setLoading(true);
    const startTime = Date.now(); // Record the start time
    setUserData({
      ...userData,
      fullName: fullName,
    });

    try {
      const response = await fetch(
        "https://api.scanandplaytambola.com/nameNumber",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: fullName,
          }),
        }
      );

      const data = await response.json();
      setApiResponse(data);

      const endTime = Date.now();
      const elapsedTime = endTime - startTime;
      const remainingTime = 5000 - elapsedTime; // Ensure a minimum of 5 seconds

      setTimeout(() => {
        setLoading(false);
        setResponseReadyToShow(true); // Now the response is ready to show
        setErrorMessage(null); // Clear error message on success
      }, Math.max(remainingTime, 0)); // Adjust timeout to ensure it is not negative
    } catch (error) {
      setErrorMessage("Failed to fetch the data. Please try again.");
      setLoading(false);
      setResponseReadyToShow(false); // Ensure we do not show any incomplete data
    } finally {
      // Decrement clicks remaining
      setClicksRemaining((prev) => prev - 1);

      // Start timer only on the first submit
      if (clicksRemaining === 5) {
        startTimer();
      }
    }
  };

  useEffect(() => {
    document.title = browserTitle;
  }, []);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true, // Optionally enable/disable arrows if needed
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 840,
        settings: {
          slidesToShow: 1,
          dots: false,
        },
      },
      {
        breakpoint: 485,
        settings: {
          slidesToShow: 1,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  const imageIndexes = [1, 8, 4, 9, 15, 6, 12, 2, 10, 7, 11];

  return (
    <div className="NameNumber">
      <Helmet>
        <title>Name Number</title>
        <meta
          name="description"
          content="Unveil your Name Number to learn about your identity according to numerology."
          title="Name Number"
        />
      </Helmet>
      <div className="content">
        <br />
        <div className="input-and-result-window">
          <div className="special">
            <h1>What does your Name reveal?</h1>
            <div>
              <p className="label">Enter your Full Name:</p>
              <input
                type="text"
                value={fullName}
                onChange={handleFullNameChange}
                placeholder="Type here..."
              />
            </div>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            <button
              onClick={handleSubmit}
              disabled={loading || clicksRemaining <= 0}
            >
              Submit
            </button>
          </div>
          <div className="special result-window">
            <h2>Result</h2>
            {loading && <Loader />}
            <p>
              Name Number:{" "}
              <b>{loading || !apiResponse ? "" : apiResponse.nameNumber}</b>
            </p>
            <p>
              Ruling Planet:{" "}
              <strong>
                {loading || !apiResponse ? "" : apiResponse.rullingPlanet}
              </strong>
            </p>
            {!loading && apiResponse && apiResponse.whatitis && (
              <>
                <h4>Interpretation:</h4>
                <p>{apiResponse.whatitis}</p>
              </>
            )}
          </div>
        </div>
        {/* Loader component is displayed when loading is true */}
        <div
          className={`result-small-box-container left-align-content ${
            hasContent ? "filled" : ""
          }`}
          id="breadcrumbs "
        >
          <div className="result-small-box">
            <div>
              <p>Personality</p>
              <p>Number: </p>
            </div>
            <p>
              <b>{loading ? "..." : apiResponse?.persnolityNumber || ""}</b>
            </p>
          </div>
          <div className="result-small-box">
            <div>
              <p>Compound</p>
              <p>Number: </p>
            </div>

            <p>
              <b>
                {loading ? "..." : apiResponse?.compoundDestinyNumber || ""}
              </b>
            </p>
          </div>
          <div className="result-small-box">
            <div>
              <p>Expression</p>
              <p>Number: </p>
            </div>

            <p>
              <b>{loading ? "..." : apiResponse?.expressionNumber || ""}</b>
            </p>
          </div>
          <div className="result-small-box">
            <div>
              <p>Destiny</p>
              <p>Number:</p>{" "}
            </div>

            <p>
              <b>{loading ? "..." : apiResponse?.destinyNumber || ""}</b>
            </p>
          </div>
          <div className="result-small-box">
            <div>
              <p>Soul Urge</p>
              <p>Number:</p>{" "}
            </div>

            <p>
              <b>{loading ? "..." : apiResponse?.soulUrge || ""}</b>
            </p>
          </div>
        </div>
        <div
          className="left-align-content"
          id="breadcrumbs"
          style={{ display: "flex", alignItems: "center" }}
        >
          <p className="share-line" style={{ margin: 0 }}>
            Please feel free to share with your Friends and Family :
          </p>
          <ShareButtons url={url} shareText={shareText} />
        </div>
        <div className="left-align-content">
          <h2>How to Calculate your Name Number :</h2>
          <div
            dangerouslySetInnerHTML={{
              __html: nameNumberInstructions.howToCalculate,
            }}
          />
          <img
            className="center-image-chaldean"
            src={chaldeanChartImage}
            alt="Chandelean Chart"
          />
        </div>
        <div className="related-tools-div">
          <h1 className="h1-center">Our Other Tools</h1>
          <div className="carousel-container">
            <Slider {...settings}>
              {imageIndexes.map((index) => (
                <div key={index}>
                  <Link to={all_images[index].link}>
                    <img
                      src={all_images[index].imageSrc}
                      alt={all_images[index].alt}
                      className="carousel-image"
                    />
                  </Link>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        <ExampleUI texts={exampleTextsNameNumber} />
        <div className="sharebuttons">
          <ShareButtons url={url} shareText={shareText} />
        </div>
        <MouseFollower mouseTitle={mouseTitle} />{" "}
        {/* Pass mouseTitle as a prop */}
        {/* FAQ Section */}
        <div className="faq-section">
          <h2 className="faq-heading">Frequently Asked Questions</h2>
          <Faq />
        </div>
      </div>
    </div>
  );
}

export default NameNumber;
