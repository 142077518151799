import React, { useState, useEffect, useContext } from "react";
import { GlobalStateContext } from "../components/GlobalVariable";
import "./HouseNumber.css";
import { houseNumberInstructions } from "./HouseNumber_How_To";
import ShareButtons from "../components/ShareButtons";
import Loader from "../components/Loader";
import MouseFollower from "../components/MouseFollower";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { all_images } from "../components/Related-Tools";
import {
  HOUSE_FAQ_QUESTION_1,
  HOUSE_FAQ_ANSWER_1,
  HOUSE_FAQ_QUESTION_2,
  HOUSE_FAQ_ANSWER_2,
  HOUSE_FAQ_QUESTION_3,
  HOUSE_FAQ_ANSWER_3,
  HOUSE_FAQ_QUESTION_4,
  HOUSE_FAQ_ANSWER_4,
  HOUSE_FAQ_QUESTION_5,
  HOUSE_FAQ_ANSWER_5,
  HOUSE_FAQ_QUESTION_6,
  HOUSE_FAQ_ANSWER_6,
  HOUSE_FAQ_QUESTION_7,
  HOUSE_FAQ_ANSWER_7,
  HOUSE_FAQ_QUESTION_8,
  HOUSE_FAQ_ANSWER_8,
  HOUSE_FAQ_QUESTION_9,
  HOUSE_FAQ_ANSWER_9,
  HOUSE_FAQ_QUESTION_10,
  HOUSE_FAQ_ANSWER_10,
} from "../components/Constants";
import exampleTextsHouseNumerology from "./HouseNumber_Example_Text";
import ExampleUI from "../components/ExampleUI";

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const rawFaqs = [
    { question: HOUSE_FAQ_QUESTION_1, answer: HOUSE_FAQ_ANSWER_1 },
    { question: HOUSE_FAQ_QUESTION_2, answer: HOUSE_FAQ_ANSWER_2 },
    { question: HOUSE_FAQ_QUESTION_3, answer: HOUSE_FAQ_ANSWER_3 },
    { question: HOUSE_FAQ_QUESTION_4, answer: HOUSE_FAQ_ANSWER_4 },
    { question: HOUSE_FAQ_QUESTION_5, answer: HOUSE_FAQ_ANSWER_5 },
    { question: HOUSE_FAQ_QUESTION_6, answer: HOUSE_FAQ_ANSWER_6 },
    { question: HOUSE_FAQ_QUESTION_7, answer: HOUSE_FAQ_ANSWER_7 },
    { question: HOUSE_FAQ_QUESTION_8, answer: HOUSE_FAQ_ANSWER_8 },
    { question: HOUSE_FAQ_QUESTION_9, answer: HOUSE_FAQ_ANSWER_9 },
    { question: HOUSE_FAQ_QUESTION_10, answer: HOUSE_FAQ_ANSWER_10 },
  ];

  const faqs = rawFaqs.filter((faq) => faq.question);

  return (
    <div className="faq-container">
      {faqs.map((faq, index) => (
        <div key={index} className="faq-item">
          <div className="faq-question" onClick={() => toggleFAQ(index)}>
            <span className="arrow">{activeIndex === index ? "-" : "+"}</span>{" "}
            {faq.question}
          </div>
          {activeIndex === index && (
            <div className="faq-answer">{faq.answer}</div>
          )}
        </div>
      ))}
    </div>
  );
};

const generateOptions = (start, end) => {
  const options = [];
  for (let i = start; i <= end; i++) {
    options.push(i.toString().padStart(2, "0"));
  }
  return options;
};

const dayOptions = generateOptions(1, 31);
const monthOptions = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const currentYear = new Date().getFullYear();
const yearOptions = generateOptions(currentYear - 100, currentYear);

function HouseNumber({ userData, setUserData }) {
  const { rateLimitCount, setRateLimitCount } = useContext(GlobalStateContext);
  const updateGlobalVariable = () => {
    //e.preventDefault();
    if (rateLimitCount > 0) {
      setRateLimitCount(rateLimitCount - 1);
    }
  };
  const [clicksRemaining, setClicksRemaining] = useState(5);
  const [, setTimerVisible] = useState(false);
  const [, setTimerSeconds] = useState(60);
  const [, setError] = useState("");
  const url = window.location.href;
  const mouseTitle = "Is your House number Lucky for you?";
  const browserTitle = "Lucky House Number";
  const [houseNumber, setHouseNumber] = useState("");
  const [dobList, setDobList] = useState([{ day: "", month: "", year: "" }]);
  const [apiResponse, setApiResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [houseNumberError, setHouseNumberError] = useState(false);
  const [dobError, setDobError] = useState(false);
  const shareText =
    "Hi, I found some intriguing insights about my house and how to match my frequency with my house number. Explore what your house number can tell you, at";

  const hasContent = houseNumber !== null;

  const handleHouseNumberChange = (event) => {
    const value = event.target.value;
    // Regex to ensure only alphanumeric values are entered
    if (/^[a-zA-Z0-9]*$/.test(value)) {
      setHouseNumber(value);
      setHouseNumberError(false); // Reset error on valid input
    }
    setApiResponse(null); // Clear previous API result
  };

  const handleDobChange = (index, field, value) => {
    const newDobList = [...dobList];
    newDobList[index][field] = value;
    setDobList(newDobList);
    setDobError(false); // Reset error on valid input
    setApiResponse(null); // Clear previous API result
  };

  const addDobField = () => {
    setDobList([...dobList, { day: "", month: "", year: "" }]);
    setApiResponse(null); // Clear previous API result
  };

  const removeDobField = (index) => {
    if (dobList.length > 1) {
      const newDobList = dobList.filter((_, i) => i !== index);
      setDobList(newDobList);
      setApiResponse(null); // Clear previous API result
    }
  };

  const startTimer = () => {
    setTimerVisible(true);

    // Timer to update seconds every second until the next minute starts
    const timerInterval = setInterval(() => {
      setTimerSeconds((prevSeconds) => {
        if (prevSeconds === 0) {
          clearInterval(timerInterval); // Clear interval when timer reaches 0
          setTimerSeconds(60); // Restart timer from 60 seconds
          setClicksRemaining(5); // Refresh clicks remaining to 5
          startTimer(); // Restart the timer
        }
        return prevSeconds - 1;
      });
    }, 1000);
  };

  const handleSubmit = async () => {
    updateGlobalVariable();
    // Clear previous errors
    setError(null);
    // Prevent submission if clicks remaining are exhausted
    if (clicksRemaining <= 0) {
      setError(
        "You have reached the maximum number of submissions. Please wait for the timer to reset."
      );
      return;
    }
    const dobFormatted = dobList.map(
      (dob) => `${dob.day}-${dob.month}-${dob.year}`
    );

    const isDobValid = dobList.some((dob) => dob.day && dob.month && dob.year);

    if (!houseNumber) {
      setHouseNumberError(true);
    } else {
      setHouseNumberError(false);
    }

    if (!isDobValid) {
      setDobError(true);
    } else {
      setDobError(false);
    }

    if (!houseNumber || !isDobValid) {
      return; // Stop submission if there are errors
    }

    setLoading(true);

    try {
      const response = await fetch("https://api.scanandplaytambola.com/house", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          houseNumber: houseNumber,
          dob: dobFormatted,
        }),
      });
      const data = await response.json();
      setTimeout(() => {
        setApiResponse(data);
        setLoading(false);
      }, 5000); // Ensure the loader shows for at least 5 seconds
    } catch (error) {
      console.error("Error fetching data from API", error);
      setApiResponse({ error: "Error fetching data from API" });
      setLoading(false);
    } finally {
      // Decrement clicks remaining
      setClicksRemaining((prev) => prev - 1);

      // Start timer only on the first submit
      if (clicksRemaining === 5) {
        startTimer();
      }
    }
  };

  useEffect(() => {
    document.title = browserTitle;
  }, []);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true, // Optionally enable/disable arrows if needed
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 840,
        settings: {
          slidesToShow: 1,
          dots: false,
        },
      },
      {
        breakpoint: 485,
        settings: {
          slidesToShow: 1,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  const imageIndexes = [0, 1, 24, 16, 3, 2, 5, 12, 13, 14, 18];

  return (
    <div className="HouseNumber">
      <Helmet>
        <title>Lucky House Number</title>
        <meta
          name="description"
          content="Discover your Lucky House Number to ensure your home’s address aligns with positive energy and brings harmony to your life."
        />
      </Helmet>
      <div className="content">
        <br></br>
        <div className="special">
          <h1>Lucky House Number Calculator</h1>
          <div>
            <p className="label">Enter your House number:</p>
            <input
              type="text"
              value={houseNumber}
              onChange={handleHouseNumberChange}
              placeholder="Please enter full address"
              className={houseNumberError ? "error-input" : ""}
            />
            {houseNumberError && (
              <p className="error-message">Please enter your House Number</p>
            )}
          </div>
          {dobList.map((dob, index) => (
            <div key={index} className="dob-field">
              <p className="label">Enter your Date of Birth:</p>
              <select
                value={dob.day}
                onChange={(e) => handleDobChange(index, "day", e.target.value)}
              >
                <option value="">Day</option>
                {dayOptions.map((day) => (
                  <option key={day} value={day}>
                    {day}
                  </option>
                ))}
              </select>
              <select
                value={dob.month}
                onChange={(e) =>
                  handleDobChange(index, "month", e.target.value)
                }
              >
                <option value="">Month</option>
                {monthOptions.map((month, idx) => (
                  <option key={idx} value={idx + 1}>
                    {month}
                  </option>
                ))}
              </select>
              <select
                value={dob.year}
                onChange={(e) => handleDobChange(index, "year", e.target.value)}
              >
                <option value="">Year</option>
                {yearOptions.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
              {dobList.length > 1 && (
                <button
                  className="remove-dob"
                  onClick={() => removeDobField(index)}
                >
                  X
                </button>
              )}
            </div>
          ))}
          {dobError && (
            <p className="error-message">
              Please enter at least 1 Date of Birth
            </p>
          )}
          <p className="house-note">
            <b>NOTE :</b> We believe our tool works best with all family members
            included
          </p>
          <button onClick={addDobField}>Add Another DOB</button>
          <button
            onClick={handleSubmit}
            disabled={loading || clicksRemaining <= 0}
          >
            Submit
          </button>
        </div>
        {loading && <Loader />}
        {apiResponse && !loading && (
          <div
            className={`left-align-content result-ui ${
              hasContent ? "filled" : ""
            }`}
            id="breadcrumbs"
          >
            {apiResponse.error ? (
              <p>{apiResponse.error}</p>
            ) : (
              <div>
                <h2 className="h2-center">Interpretation</h2>
                {apiResponse.houseNumber && (
                  <p>
                    <b>House Number:</b> {apiResponse.houseNumber}
                  </p>
                )}
                {apiResponse.generalforMorethanOne && (
                  <p>
                    <b>General for More Than One:</b>{" "}
                    {apiResponse.generalforMorethanOne}
                  </p>
                )}
                {apiResponse.interpretation && (
                  <p>{apiResponse.interpretation}</p>
                )}
                {apiResponse.lifepathInterpretation.length > 0 && (
                  <div>
                    <p>
                      <b>Life Path Interpretations:</b>
                      <ul>
                        {apiResponse.lifepathInterpretation.map(
                          (interpretation, i) => (
                            <li key={i}>{interpretation}</li>
                          )
                        )}
                      </ul>
                    </p>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        <div
          className="left-align-content"
          id="breadcrumbs"
          style={{ display: "flex", alignItems: "center" }}
        >
          <p className="share-line" style={{ margin: 0 }}>
            Please feel free to share with your Friends and Family :
          </p>
          <ShareButtons url={url} shareText={shareText} />
        </div>
        <div className="left-align-content">
          <h2>House Number Calculation</h2>
          <div>{houseNumberInstructions.howToCalculate}</div>
        </div>
        <div className="related-tools-div">
          <h1 className="h1-center">Our Other Tools</h1>
          <div className="carousel-container">
            <Slider {...settings}>
              {imageIndexes.map((index) => (
                <div key={index}>
                  <Link to={all_images[index].link}>
                    <img
                      src={all_images[index].imageSrc}
                      alt={all_images[index].alt}
                      className="carousel-image"
                    />
                  </Link>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        <ExampleUI texts={exampleTextsHouseNumerology} />
        <div className="sharebuttons">
          <ShareButtons url={url} shareText={shareText} />
        </div>
        <MouseFollower mouseTitle={mouseTitle} />{" "}
        {/* Pass mouseTitle as a prop */}
        {/* FAQ Section */}
        <div className="faq-section">
          <h2 className="faq-heading">Frequently Asked Questions</h2>
          <Faq />
        </div>
      </div>
    </div>
  );
}

export default HouseNumber;
