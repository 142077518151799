// src/GlobalState.js
import React, { createContext, useState, useEffect, useRef } from "react";

export const GlobalStateContext = createContext();

const getInitialState = () => {
  const savedDay = localStorage.getItem("day");
  const savedMonth = localStorage.getItem("month");
  const savedYear = localStorage.getItem("year");
  const savedFullName = localStorage.getItem("fullName");

  return {
    day: savedDay !== null ? parseInt(savedDay, 10) : 23,
    month: savedMonth !== null ? parseInt(savedMonth, 10) : 10,
    year: savedYear !== null ? parseInt(savedYear, 10) : 1990,
    fullName: savedFullName || "", // Default to empty string if not found
  };
};

const getInitialRateLimitCount = () => {
  const savedCount = localStorage.getItem("RATE_LIMIT_COUNT");
  return savedCount !== null ? parseInt(savedCount, 10) : 10; // default value 10
};

const getInitialTimerValue = () => {
  const savedTimer = localStorage.getItem("TIMER");
  return savedTimer !== null ? parseInt(savedTimer, 10) : 59; // default timer value 59
};

export const GlobalStateProvider = ({ children }) => {
  const initialState = getInitialState();
  const initialDay = initialState.day;
  const initialMonth = initialState.month;
  const initialYear = initialState.year;
  const initialFullName = initialState.fullName;
  const [csrfToken, setCsrfToken] = useState(null);

  const [day, setDay] = useState(initialDay);
  const [month, setMonth] = useState(initialMonth);
  const [year, setYear] = useState(initialYear);
  const [rateLimitCount, setRateLimitCount] = useState(
    getInitialRateLimitCount
  );
  const [timer, setTimer] = useState(getInitialTimerValue);
  const [fullName, setFullName] = useState(initialFullName); // Initialize with the value from localStorage

  const intervalRef = useRef(null);

  useEffect(() => {
    localStorage.setItem("RATE_LIMIT_COUNT", rateLimitCount);
  }, [rateLimitCount]);

  useEffect(() => {
    localStorage.setItem("TIMER", timer);
  }, [timer]);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer === 0) {
          setRateLimitCount(10); // Reset rate limit count to default value
          return 59; // Reset timer to 59
        }
        return prevTimer - 1;
      });
    }, 1000);

    return () => clearInterval(intervalRef.current);
  }, []);

  useEffect(() => {
    localStorage.setItem("day", day);
  }, [day]);

  useEffect(() => {
    localStorage.setItem("month", month);
  }, [month]);

  useEffect(() => {
    localStorage.setItem("year", year);
  }, [year]);

  useEffect(() => {
    localStorage.setItem("fullName", fullName); // Save fullName to localStorage
  }, [fullName]);

  return (
    <GlobalStateContext.Provider
      value={{
        csrfToken,
        setCsrfToken,
        rateLimitCount,
        setRateLimitCount,
        timer,
        fullName,
        setFullName,
        day,
        setDay,
        month,
        setMonth,
        year,
        setYear,
      }}
    >
      {children}
    </GlobalStateContext.Provider>
  );
};
