import React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import "./Home.css";
import { Helmet } from "react-helmet";

function Home() {
  const url = window.location.href;
  const browserTitle =
    "Elite Numerology: Free Tools & One-Stop for All Numerology Services";
  const cards = [
    {
      heading: "Balance Number",
      description:
        "Find your Balance Number to manage stress and maintain equilibrium during difficult times, based on your numerology profile.",
      link: "/balance-number-calculator",
    },
    {
      heading: "Bank Account Number",
      description:
        "Identify your Lucky Bank Account Number to attract financial stability, success, and prosperity in your monetary transactions.",
      link: "/is-your-bank-account-number-lucky",
    },
    {
      heading: "Best Day to Fast",
      description:
        "Identify your optimal fasting days to align with cosmic energies, promoting health, spiritual growth, and overall well-being.",
      link: "/personal-fast-day-calculator-numerology",
    },
    {
      heading: "Business Name",
      description:
        "Choose a Lucky Business Name to ensure your business vibrates with positive energy, leading to success and growth.",
      link: "/lucky-business-name-calculator",
    },
    {
      heading: "Daily Biorhythm Chart",
      description:
        "Use the Biorhythm Chart to track your physical, emotional, and intellectual cycles, optimizing your daily activities and well-being.",
      link: "/daily-numerological-chart",
    },
    {
      heading: "Destiny Number",
      description:
        "Unveil your Destiny Number to learn about your life’s mission and the path you are meant to follow according to numerology.",
      link: "/destiny-number-calculator",
    },

    {
      heading: "Hidden Passion Number",
      description:
        "Reveal your Hidden Passion Number to uncover your deepest talents and passions that influence your life’s journey.",
      link: "/hidden-passion-number-calculator",
    },
    {
      heading: "House Number",
      description:
        "Discover your Lucky House Number to ensure your home’s address aligns with positive energy and brings harmony to your life.",
      link: "/is-your-house-number-lucky-with-remedies",
    },
    {
      heading: "Jersey Number",
      description:
        "Discover your lucky jersey number and enhance your sports performance with numerology insights.",
      link: "/jersey-number-calculator",
    },
    {
      heading: "Karmic Debt",
      description:
        "Identify your Karmic Debt Numbers to recognize past life lessons you need to address to achieve personal growth and balance.",
      link: "/what-are-karmic-debt-numbers-in-numerology",
    },
    {
      heading: "Kua Number",
      description:
        "Discover your Kua number to find your favorable directions and improve your luck and well-being based on Feng Shui principles.",
      link: "/kua-number-calculator",
    },
    {
      heading: "Kua Number Compatibility",
      description:
        "Analyze Kua Number Compatibility to determine the harmony between you and your partner, enhancing your relationship.",
      link: "/numerology-kua-compatibility",
    },
    {
      heading: "Life Path Number",
      description:
        "Calculate your Life Path Number to uncover your life's purpose, strengths, and challenges based on your birth date.",
      link: "/life-path-number-calculator",
    },
    {
      heading: "Lo Shu Grid",
      description:
        "Explore the Lo Shu Grid to analyze your personality traits and life patterns based on the ancient Chinese numerology square.",
      link: "/create-your-lo-shu-grid-missing-number-remedies",
    },
    {
      heading: "Maturity Number",
      description:
        "Calculate your Maturity Number to gain insights into your true self and the fulfillment you seek later in life.",
      link: "/numerology-maturity-number-calculator",
    },
    {
      heading: "Mobile Number",
      description:
        "Find your Lucky Mobile Number to enhance communication, attract prosperity, and maintain positive connections.",
      link: "/is-your-mobile-number-lucky",
    },
    {
      heading: "Monthly Biorhythm Chart",
      description:
        "Use the Biorhythm Chart to track your physical, emotional, and intellectual cycles, optimizing your daily activities and well-being.",
      link: "/personalized-monthly-biorhythm-chart",
    },

    {
      heading: "Name Number",
      description:
        "Unveil your Name Number to learn about your life and the special meaning held by your name according to numerology.",
      link: "/name-number-calculator",
    },
    {
      heading: "Partner Compatibility",
      description:
        "Analyze Partner Compatibility to determine the harmony between you and your partner, enhancing your relationship.",
      link: "/numerology-partner-compatibility",
    },
    {
      heading: "Personal Year Number",
      description:
        "Find your Personal Year Number to understand the themes and energies influencing your current year for better decision-making.",
      link: "/numerology-personal-year-calculator",
    },
    {
      heading: "Personality Number",
      description:
        "Unveil your Personality Number to learn about your behaviour and the best way to achieve your potential according to numerology.",
      link: "/personality-number-calculator",
    },
    {
      heading: "Pinnacle Number",
      description:
        "Discover your Pinnacle Numbers to understand the key phases of your life, highlighting opportunities and challenges.",
      link: "/pinnacle-number-calculator",
    },
    {
      heading: "Soul Urge Number",
      description:
        "Reveal your Soul Urge Number to understand your inner desires, motivations, and what drives you at a deeper level.",
      link: "/soul-urge-number-calculator",
    },
    {
      heading: "Subconscious Self Number",
      description:
        "Determine your Subconscious Self Number to understand your instinctual reactions and hidden strengths.",
      link: "/subconscious-self-number-calculator",
    },
    {
      heading: "Vehicle Number",
      description:
        "Determine your Lucky Vehicle Number to attract positive energy and good fortune while driving or traveling.",
      link: "/is-your-vehicle-number-lucky-with-remedies",
    },
  ];

  useEffect(() => {
    document.title = browserTitle; // Set document title on component mount
  }, []); // Empty dependency array to run only once

  return (
    <div className="home-container">
      <Helmet>
        <title>
          Elite Numerology: Free Tools & One-Stop for All Numerology Services
        </title>
        <meta
          name="description"
          content="EliteNumerology.com - the ultimate destination for learning numerology, finding the perfect name change, and accessing free tools and Get Interpretation. We also Provide Jersey Number Calculation for Free"
        />
      </Helmet>
      <div className="content">
        <div className="card-grid">
          {cards.map((card, index) => (
            <Link
              key={index}
              to={card.link}
              className={`card column-${(index % 5) + 1}`}
            >
              <h2 className="card-heading">{card.heading}</h2>
              <p className="card-description">{card.description}</p>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Home;
