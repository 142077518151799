import React, { useEffect } from "react";
// import MouseFollower from "../components/MouseFollower";
import { Helmet } from "react-helmet";

// const mouseTitle = "Payment Successful";
const browserTitle = "Payment Failed";

const PaymentFail = () => {
  useEffect(() => {
    document.title = browserTitle; // Set document title on component mount
  }, []); // Empty dependency array to run only once

  return (
    <div className="payment-successful">
      <Helmet>
        <title>Elite Numerology - Payment Failed</title>
      </Helmet>
      <h1>Payment Failed</h1>
      {/* <MouseFollower mouseTitle={mouseTitle} /> */}
    </div>
  );
};

export default PaymentFail;
