import compatibilityChartImage from "../assets/Compatibility-1.png";
import lifePathGridImage from "../assets/LifepathCompatibility-1.png";
import { Link } from "react-router-dom";

export const partnerInstructions = {
  howToCalculate: (
    <div>
      <p>Write the name and DOB of both partners.</p>

      <p>
        Calculate the name number and life path number of both partners (Click
        here to know how to calculate{" "}
        <Link className="midpagelink" to="/name-number-calculator">
          Name Number
        </Link>{" "}
        and{" "}
        <Link className="midpagelink" to="/life-path-number-calculator">
          Life Path Number
        </Link>
        ).
      </p>

      <p>To calculate name number compatibility, use this chart:</p>
      <img
        className="center-image-compatibility"
        src={compatibilityChartImage}
        alt="Compatibility Chart"
      />

      <p>
        To calculate the relationship between the life path numbers of both
        partners, use the grid below:
      </p>
      <img
        className="center-image-compatibility"
        src={lifePathGridImage}
        alt="Life Path Grid"
      />
    </div>
  ),
};
