import React, { useState, useEffect, useContext } from "react";
import { GlobalStateContext } from "../components/GlobalVariable";
import "./LuckyVehicle.css";
import compatibilityChart from "../assets/Compatibility-1.png";
import { vehicleInstructions } from "./LuckyVehicle_How_To";
import DateOfBirthSelector from "../components/DateOfBirthSelector";
import ShareButtons from "../components/ShareButtons";
import Loader from "../components/Loader";
import MouseFollower from "../components/MouseFollower";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { all_images } from "../components/Related-Tools";
import {
  VEHICLE_FAQ_QUESTION_1,
  VEHICLE_FAQ_ANSWER_1,
  VEHICLE_FAQ_QUESTION_2,
  VEHICLE_FAQ_ANSWER_2,
  VEHICLE_FAQ_QUESTION_3,
  VEHICLE_FAQ_ANSWER_3,
  VEHICLE_FAQ_QUESTION_4,
  VEHICLE_FAQ_ANSWER_4,
  VEHICLE_FAQ_QUESTION_5,
  VEHICLE_FAQ_ANSWER_5,
  VEHICLE_FAQ_QUESTION_6,
  VEHICLE_FAQ_ANSWER_6,
  VEHICLE_FAQ_QUESTION_7,
  VEHICLE_FAQ_ANSWER_7,
  VEHICLE_FAQ_QUESTION_8,
  VEHICLE_FAQ_ANSWER_8,
  VEHICLE_FAQ_QUESTION_9,
  VEHICLE_FAQ_ANSWER_9,
  VEHICLE_FAQ_QUESTION_10,
  VEHICLE_FAQ_ANSWER_10,
} from "../components/Constants";
import exampleTextsVehicle from "./LuckyVehicle_Example_Text";
import ExampleUI from "../components/ExampleUI";

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const rawFaqs = [
    { question: VEHICLE_FAQ_QUESTION_1, answer: VEHICLE_FAQ_ANSWER_1 },
    { question: VEHICLE_FAQ_QUESTION_2, answer: VEHICLE_FAQ_ANSWER_2 },
    { question: VEHICLE_FAQ_QUESTION_3, answer: VEHICLE_FAQ_ANSWER_3 },
    { question: VEHICLE_FAQ_QUESTION_4, answer: VEHICLE_FAQ_ANSWER_4 },
    { question: VEHICLE_FAQ_QUESTION_5, answer: VEHICLE_FAQ_ANSWER_5 },
    { question: VEHICLE_FAQ_QUESTION_6, answer: VEHICLE_FAQ_ANSWER_6 },
    { question: VEHICLE_FAQ_QUESTION_7, answer: VEHICLE_FAQ_ANSWER_7 },
    { question: VEHICLE_FAQ_QUESTION_8, answer: VEHICLE_FAQ_ANSWER_8 },
    { question: VEHICLE_FAQ_QUESTION_9, answer: VEHICLE_FAQ_ANSWER_9 },
    { question: VEHICLE_FAQ_QUESTION_10, answer: VEHICLE_FAQ_ANSWER_10 },
  ];

  const faqs = rawFaqs.filter((faq) => faq.question);

  return (
    <div className="faq-container">
      {faqs.map((faq, index) => (
        <div key={index} className="faq-item">
          <div className="faq-question" onClick={() => toggleFAQ(index)}>
            <span className="arrow">{activeIndex === index ? "-" : "+"}</span>{" "}
            {faq.question}
          </div>
          {activeIndex === index && (
            <div className="faq-answer" onClick={() => toggleFAQ(index)}>
              {faq.answer}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

function LuckyVehicle({ userData, setUserData }) {
  const {
    day,
    setDay,
    month,
    setMonth,
    year,
    setYear,
    rateLimitCount,
    setRateLimitCount,
  } = useContext(GlobalStateContext);
  const updateGlobalVariable = () => {
    //e.preventDefault();
    if (rateLimitCount > 0) {
      setRateLimitCount(rateLimitCount - 1);
    }
  };
  const [clicksRemaining, setClicksRemaining] = useState(5);
  const [, setTimerVisible] = useState(false);
  const [, setTimerSeconds] = useState(60);
  const [error, setError] = useState("");
  const url = window.location.href;
  const mouseTitle = "Is your Vehicle Number Lucky for you?";
  const browserTitle = "Lucky Vehicle Number";
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [daysInMonth, setDaysInMonth] = useState(31);
  const [apiResponse, setApiResponse] = useState(null);
  const [loading, setLoading] = useState(false);

  const shareText =
    "Easy way to tune your Vehicle number frequency with these Oriental remedies. Find out yours at";

  const handleVehicleNumberChange = (event) => {
    const value = event.target.value;
    // Regex to ensure only alphanumeric values are entered
    if (/^[a-zA-Z0-9]*$/.test(value)) {
      setVehicleNumber(value);
    }
  };

  const hasContent = vehicleNumber !== null;

  useEffect(() => {
    const updateDaysInMonth = (month, year) => {
      const days = new Date(year, month, 0).getDate();
      setDaysInMonth(days);
      if (day > days) {
        setError("Invalid date, re-check and enter again");
        setDay(1);
      } else {
        setError("");
      }
    };
    updateDaysInMonth(month, year);
  }, [month, year, day]);

  const handleDayChange = (event) => {
    setDay(parseInt(event.target.value, 10));
    setApiResponse(null); // Clear previous API result
  };
  const handleMonthChange = (event) => {
    setMonth(parseInt(event.target.value, 10));
    setApiResponse(null); // Clear previous API result
  };
  const handleYearChange = (event) => {
    setYear(parseInt(event.target.value, 10));
    setApiResponse(null); // Clear previous API result
  };

  useEffect(() => {
    document.title = browserTitle; // Set document title on component mount
  }, []); // Empty dependency array to run only once

  const startTimer = () => {
    setTimerVisible(true);

    // Timer to update seconds every second until the next minute starts
    const timerInterval = setInterval(() => {
      setTimerSeconds((prevSeconds) => {
        if (prevSeconds === 0) {
          clearInterval(timerInterval); // Clear interval when timer reaches 0
          setTimerSeconds(60); // Restart timer from 60 seconds
          setClicksRemaining(5); // Refresh clicks remaining to 5
          startTimer(); // Restart the timer
        }
        return prevSeconds - 1;
      });
    }, 1000);
  };

  const handleSubmit = async () => {
    updateGlobalVariable();
    // Clear previous errors
    setError(null);

    setApiResponse(null); // Clear previous API result

    // Prevent submission if clicks remaining are exhausted
    if (clicksRemaining <= 0) {
      setError(
        "You have reached the maximum number of submissions. Please wait for the timer to reset."
      );
      return;
    }
    // Check if either vehicleNumber or any of day, month, year is empty
    if (!vehicleNumber || !day || !month || !year) {
      setError("Please enter Vehicle Number.");
      return;
    }

    setLoading(true);
    const startTime = Date.now(); // Record the start time
    setUserData({
      ...userData,
      day: day,
      month: month,
      year: year,
    });

    const formattedDateOfBirth = `${day}-${month}-${year}`;

    try {
      const response = await fetch(
        "https://api.scanandplaytambola.com/luckyVehcleNumber",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            dateOfBirth: formattedDateOfBirth,
            vehicleNumber: vehicleNumber,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      // Ensure loader is displayed for a minimum of 5 seconds
      const endTime = Date.now();
      const elapsedTime = endTime - startTime;
      const remainingTime = 5000 - elapsedTime; // Ensure a minimum of 5 seconds

      setTimeout(
        () => {
          setApiResponse(data);
          setLoading(false);
        },
        remainingTime > 0 ? remainingTime : 0
      );
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      setError(
        "There was a problem with the fetch operation. Please try again."
      );
      setLoading(false); // Ensure loader is turned off in case of error
    } finally {
      // Decrement clicks remaining
      setClicksRemaining((prev) => prev - 1);

      // Start timer only on the first submit
      if (clicksRemaining === 5) {
        startTimer();
      }
    }
  };

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true, // Optionally enable/disable arrows if needed
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 840,
        settings: {
          slidesToShow: 1,
          dots: false,
        },
      },
      {
        breakpoint: 485,
        settings: {
          slidesToShow: 1,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  const imageIndexes = [0, 1, 15, 8, 2, 17, 17, 19, 6, 12, 11, 9];

  return (
    <div className="LuckyVehicle">
      <Helmet>
        <title>Lucky Vehicle Number</title>
        <meta
          name="description"
          content="Determine your Lucky Vehicle Number to attract positive energy and good fortune while driving or traveling."
        />
      </Helmet>
      <div className="content">
        <br></br>
        <div className="input-and-result-window">
          <div className="special">
            <h1>Lucky Vehicle Number Calculator</h1>
            <div>
              <p className="label">Enter your Vehicle number:</p>
              <input
                type="text" // Changed to text to better control input
                value={vehicleNumber}
                placeholder="For example : 5409"
                onChange={handleVehicleNumberChange}
              />
              {error && <div className="error-message">{error}</div>}
            </div>
            <div>
              <DateOfBirthSelector
                day={day}
                month={month}
                year={year}
                daysInMonth={daysInMonth}
                handleDayChange={handleDayChange}
                handleMonthChange={handleMonthChange}
                handleYearChange={handleYearChange}
              />
            </div>
            <button
              onClick={handleSubmit}
              disabled={loading || clicksRemaining <= 0}
            >
              Submit
            </button>

            {/* Loader component is displayed when loading is true */}
            {loading && <Loader />}
          </div>
          <div className="special result-window">
            <h2>Result</h2>
            {loading && <Loader />}
            <p>
              Compatibility :{" "}
              {!loading && apiResponse ? apiResponse.lucky_Unlucky_Neytral : ""}
            </p>
            <p>
              Vehicle Number :{" "}
              <b>{!loading && apiResponse ? apiResponse.vechileNumber : ""}</b>
            </p>
            <p>
              Destiny Number :{" "}
              <b>{!loading && apiResponse ? apiResponse.destinyNumber : ""}</b>
            </p>
            {!loading && apiResponse && (
              <p>
                <em>For more details, see below...</em>
              </p>
            )}
          </div>
        </div>
        {apiResponse && (
          <div
            className={`left-align-content result-ui ${
              hasContent ? "filled" : ""
            }`}
            id="breadcrumbs"
          >
            <h2 className="api-h2">Remedies :</h2>
            <p>
              <strong>Suggested Remedy:</strong> {apiResponse.suggested_Remedy}
            </p>
            <p>
              <strong>Reason for Remedy:</strong> {apiResponse.remedy_why}
            </p>
          </div>
        )}
        <div
          className="left-align-content"
          id="breadcrumbs"
          style={{ display: "flex", alignItems: "center" }}
        >
          <p className="share-line" style={{ margin: 0 }}>
            Please feel free to share with your Friends and Family :
          </p>
          <ShareButtons url={url} shareText={shareText} />
        </div>
        <div className="left-align-content">
          <h2>How to Calculate: </h2>
          <div
            dangerouslySetInnerHTML={{
              __html: vehicleInstructions.howToCalculate,
            }}
          />
          <img
            className="center-image-compatibility"
            src={compatibilityChart}
            alt="Chandelean Chart"
          />
        </div>
        <div className="related-tools-div">
          <h1 className="h1-center">Our Other Tools</h1>
          <div className="carousel-container">
            <Slider {...settings}>
              {imageIndexes.map((index) => (
                <div key={index}>
                  <Link to={all_images[index].link}>
                    <img
                      src={all_images[index].imageSrc}
                      alt={all_images[index].alt}
                      className="carousel-image"
                    />
                  </Link>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        <ExampleUI texts={exampleTextsVehicle} />
        <div className="sharebuttons">
          <ShareButtons url={url} shareText={shareText} />
        </div>
        <MouseFollower mouseTitle={mouseTitle} />{" "}
        {/* Pass mouseTitle as a prop */}
        {/* FAQ Section */}
        <div className="faq-section">
          <h2 className="faq-heading">Frequently Asked Questions</h2>
          <Faq />
        </div>
      </div>
    </div>
  );
}

export default LuckyVehicle;
