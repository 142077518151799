import React, { useState } from "react";
import "./ModalWindows.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const PersonalDetails = ({ isOpen, onClose }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Please fill in your details for our Personalised services</h2>
        <form className="details-form">
          <div className="form-group form-group-dob">
            <label>Full Name -</label>
            <input
              type="text"
              placeholder="Enter Here"
              className="modal-input"
              required
            />
          </div>
          <div className="form-group form-group-dob">
            <label>Date of Birth -</label>
            <DatePicker
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              dateFormat="dd-MM-yyyy"
              placeholderText="Select date"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              required
            />
          </div>
          <div className="form-group form-group-gender form-group-center">
            <label>Gender -</label>
            <div>
              <label className="gender-label">
                <input
                  className="gender-selector"
                  type="radio"
                  name="gender"
                  value="male"
                  required
                />{" "}
                Male
              </label>
              <label className="gender-label">
                <input
                  className="gender-selector"
                  type="radio"
                  name="gender"
                  value="female"
                  required
                />{" "}
                Female
              </label>
            </div>
          </div>
          <div className="form-group form-group-details">
            <textarea
              placeholder="[OPTIONAL] Any other details..."
              className="modal-input modal-input--textarea"
              rows="4"
            ></textarea>
          </div>
          <div className="form-group form-group-center">
            <button type="submit" className="modal-submit-button">
              Submit
            </button>
          </div>
        </form>
        <button className="close-modal-button" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default PersonalDetails;
