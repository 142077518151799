import React from "react";
import "./ModalWindows.css"; // You can style the modal here
import { Link } from "react-router-dom";

const ConfirmNewPassword = ({ onClose }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <p>New password has been updated.</p>
        <p>
          Please <Link to="/login">login </Link>
          to continue.
        </p>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default ConfirmNewPassword;
