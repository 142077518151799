import React, { useEffect, useState, useRef } from "react";
import MouseFollower from "../components/MouseFollower";
import { Helmet } from "react-helmet";
import { GoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

// const mouseTitle = "Sign Up and Login";
const browserTitle = "Login";

const DeleteAccountLogin = () => {
  const navigate = useNavigate();
  const formRef = useRef(null); // Use ref to get the form

  const handleLinkClick = (e) => {
    e.preventDefault();

    if (formRef.current.checkValidity()) {
      navigate("/delete-account-verify"); // Navigate only if the form is valid
    } else {
      formRef.current.reportValidity(); // Trigger form validation messages
    }
  };

  useEffect(() => {
    document.title = browserTitle; // Set document title on component mount

    const emailInputs = document.querySelectorAll('input[type="email"]');

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    emailInputs.forEach((input) => {
      input.addEventListener("input", function () {
        if (!emailRegex.test(this.value)) {
          this.setCustomValidity("Please enter a valid email id");
        } else {
          this.setCustomValidity("");
        }
      });
      input.addEventListener("invalid", function () {
        this.setCustomValidity("Please enter a valid email id");
      });
    });

    const passwordInput = document.querySelectorAll('input[name="password"]');

    const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@_#$%]).{8,}$/;

    passwordInput.forEach((input) => {
      if (input.name === "password") {
        // Apply regex only to the password field
        input.addEventListener("input", function () {
          if (!passwordRegex.test(this.value)) {
            this.setCustomValidity(
              "Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, one digit, and one special character (@, _, $, #, or %)."
            );
          } else {
            this.setCustomValidity("");
          }
        });

        input.addEventListener("invalid", function () {
          this.setCustomValidity(
            "Password must have at least 8 characters, contain one uppercase letter, one lowercase letter, one digit, and one special character."
          );
        });
      }
    });

    const mobileInput = document.querySelector('input[name="mobile"]');
    mobileInput.addEventListener("input", function () {
      this.value = this.value.replace(/[^0-9]/g, ""); // Replace non-numeric characters with an empty string
    });
  }, []); // Empty dependency array to run only once

  const [passwordVisibility, setPasswordVisibility] = useState([
    false,
    false,
    false,
  ]);

  const [selectedPlan, setSelectedPlan] = useState("");

  const plans = [
    { id: "plan1", name: "PLAN 1", description: "Description for Plan 1" },
    { id: "plan2", name: "PLAN 2", description: "Description for Plan 2" },
    { id: "plan3", name: "PLAN 3", description: "Description for Plan 3" },
  ];

  const togglePasswordVisibility = (index) => {
    setPasswordVisibility((prevVisibility) =>
      prevVisibility.map((visible, i) => (i === index ? !visible : visible))
    );
  };

  const handleSelectPlan = (planId) => {
    setSelectedPlan(planId);
  };

  const passwordRef = useRef(null);
  const confirmPasswordRef = useRef(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    // No custom validation for password matching
  };

  const handleGoogleLoginSuccess = (credentialResponse) => {
    console.log(credentialResponse);
    // Send token to backend for validation and login
  };

  const handleGoogleLoginError = () => {
    console.error("Google Login Failed");
  };

  return (
    <div className="signupandlogin">
      <Helmet>
        <title>Elite Numerology - Sign Up and Login</title>
      </Helmet>
      <div className="signup-and-login">
        <div className="sign-up">
          <h3>First, please verify your User credentials -</h3>
          <form ref={formRef} className="sign-up-form" id="yourFormID">
            <label>
              <input type="email" placeholder="Email" name="email" required />
            </label>
            <label>
              <input
                type={passwordVisibility[0] ? "text" : "password"}
                name="password"
                placeholder="Password"
                id="password-input"
                required
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="#000000"
                viewBox="0 0 256 256"
                className="eye eye--closed"
                onClick={() => togglePasswordVisibility(0)}
                style={{ display: passwordVisibility[0] ? "none" : "block" }}
              >
                <path d="M228,175a8,8,0,0,1-10.92-3l-19-33.2A123.23,123.23,0,0,1,162,155.46l5.87,35.22a8,8,0,0,1-6.58,9.21A8.4,8.4,0,0,1,160,200a8,8,0,0,1-7.88-6.69l-5.77-34.58a133.06,133.06,0,0,1-36.68,0l-5.77,34.58A8,8,0,0,1,96,200a8.4,8.4,0,0,1-1.32-.11,8,8,0,0,1-6.58-9.21L94,155.46a123.23,123.23,0,0,1-36.06-16.69L39,172A8,8,0,1,1,25.06,164l20-35a153.47,153.47,0,0,1-19.3-20A8,8,0,1,1,38.22,99c16.6,20.54,45.64,45,89.78,45s73.18-24.49,89.78-45A8,8,0,1,1,230.22,109a153.47,153.47,0,0,1-19.3,20l20,35A8,8,0,0,1,228,175Z"></path>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="#000000"
                viewBox="0 0 256 256"
                className="eye eye--open"
                onClick={() => togglePasswordVisibility(0)}
                style={{ display: passwordVisibility[0] ? "block" : "none" }}
              >
                <path d="M247.31,124.76c-.35-.79-8.82-19.58-27.65-38.41C194.57,61.26,162.88,48,128,48S61.43,61.26,36.34,86.35C17.51,105.18,9,124,8.69,124.76a8,8,0,0,0,0,6.5c.35.79,8.82,19.57,27.65,38.4C61.43,194.74,93.12,208,128,208s66.57-13.26,91.66-38.34c18.83-18.83,27.3-37.61,27.65-38.4A8,8,0,0,0,247.31,124.76ZM128,192c-30.78,0-57.67-11.19-79.93-33.25A133.47,133.47,0,0,1,25,128,133.33,133.33,0,0,1,48.07,97.25C70.33,75.19,97.22,64,128,64s57.67,11.19,79.93,33.25A133.46,133.46,0,0,1,231.05,128C223.84,141.46,192.43,192,128,192Zm0-112a48,48,0,1,0,48,48A48.05,48.05,0,0,0,128,80Zm0,80a32,32,0,1,1,32-32A32,32,0,0,1,128,160Z"></path>
              </svg>
            </label>

            <a className="forgot-password login-link" onClick={handleLinkClick}>
              Login
            </a>
            <Link className="forgot-password" to="/forgot-password">
              Forgot Password?
            </Link>
          </form>
          <p className="not-a-member">You can also continue using</p>
          <div className="google-and-facebook-icons-div">
            <GoogleLogin
              onSuccess={handleGoogleLoginSuccess}
              onError={handleGoogleLoginError}
            />
            <p className="not-a-member">or</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="32"
              height="32"
              className="google-and-facebook-icons-svg"
              viewBox="0 0 48 48"
            >
              <path
                fill="#0081fb"
                d="M47,29.36l-2.193,1.663L42.62,29.5c0-0.16,0-0.33-0.01-0.5c0-0.16,0-0.33-0.01-0.5	c-0.14-3.94-1.14-8.16-3.14-11.25c-1.54-2.37-3.51-3.5-5.71-3.5c-2.31,0-4.19,1.38-6.27,4.38c-0.06,0.09-0.13,0.18-0.19,0.28	c-0.04,0.05-0.07,0.1-0.11,0.16c-0.1,0.15-0.2,0.3-0.3,0.46c-0.9,1.4-1.84,3.03-2.86,4.83c-0.09,0.17-0.19,0.34-0.28,0.51	c-0.03,0.04-0.06,0.09-0.08,0.13l-0.21,0.37l-1.24,2.19c-2.91,5.15-3.65,6.33-5.1,8.26C14.56,38.71,12.38,40,9.51,40	c-3.4,0-5.56-1.47-6.89-3.69C1.53,34.51,1,32.14,1,29.44l4.97,0.17c0,1.76,0.38,3.1,0.89,3.92C7.52,34.59,8.49,35,9.5,35	c1.29,0,2.49-0.27,4.77-3.43c1.83-2.53,3.99-6.07,5.44-8.3l1.37-2.09l0.29-0.46l0.3-0.45l0.5-0.77c0.76-1.16,1.58-2.39,2.46-3.57	c0.1-0.14,0.2-0.28,0.31-0.42c0.1-0.14,0.21-0.28,0.31-0.41c0.9-1.15,1.85-2.22,2.87-3.1c1.85-1.61,3.84-2.5,5.85-2.5	c3.37,0,6.58,1.95,9.04,5.61c2.51,3.74,3.82,8.4,3.97,13.25c0.01,0.16,0.01,0.33,0.01,0.5C47,29.03,47,29.19,47,29.36z"
              ></path>
              <linearGradient
                id="wSMw7pqi7WIWHewz2_TZXa_PvvcWRWxRKSR_gr1"
                x1="42.304"
                x2="13.533"
                y1="24.75"
                y2="24.75"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0" stop-color="#0081fb"></stop>
                <stop offset=".995" stop-color="#0064e1"></stop>
              </linearGradient>
              <path
                fill="url(#wSMw7pqi7WIWHewz2_TZXa_PvvcWRWxRKSR_gr1)"
                d="M4.918,15.456	C7.195,11.951,10.483,9.5,14.253,9.5c2.184,0,4.354,0.645,6.621,2.493c2.479,2.02,5.122,5.346,8.419,10.828l1.182,1.967	c2.854,4.746,4.477,7.187,5.428,8.339C37.125,34.606,37.888,35,39,35c2.82,0,3.617-2.54,3.617-5.501L47,29.362	c0,3.095-0.611,5.369-1.651,7.165C44.345,38.264,42.387,40,39.093,40c-2.048,0-3.862-0.444-5.868-2.333	c-1.542-1.45-3.345-4.026-4.732-6.341l-4.126-6.879c-2.07-3.452-3.969-6.027-5.068-7.192c-1.182-1.254-2.642-2.754-5.067-2.754	c-1.963,0-3.689,1.362-5.084,3.465L4.918,15.456z"
              ></path>
              <linearGradient
                id="wSMw7pqi7WIWHewz2_TZXb_PvvcWRWxRKSR_gr2"
                x1="7.635"
                x2="7.635"
                y1="32.87"
                y2="13.012"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0" stop-color="#0081fb"></stop>
                <stop offset=".995" stop-color="#0064e1"></stop>
              </linearGradient>
              <path
                fill="url(#wSMw7pqi7WIWHewz2_TZXb_PvvcWRWxRKSR_gr2)"
                d="M14.25,14.5	c-1.959,0-3.683,1.362-5.075,3.465C7.206,20.937,6,25.363,6,29.614c0,1.753-0.003,3.072,0.5,3.886l-3.84,2.813	C1.574,34.507,1,32.2,1,29.5c0-4.91,1.355-10.091,3.918-14.044C7.192,11.951,10.507,9.5,14.27,9.5L14.25,14.5z"
              ></path>
              <path
                d="M21.67,20.27l-0.3,0.45l-0.29,0.46c0.71,1.03,1.52,2.27,2.37,3.69l0.21-0.37c0.02-0.04,0.05-0.09,0.08-0.13 c0.09-0.17,0.19-0.34,0.28-0.51C23.19,22.5,22.39,21.29,21.67,20.27z M24.94,15.51c-0.11,0.14-0.21,0.28-0.31,0.42 c0.73,0.91,1.47,1.94,2.25,3.1c0.1-0.16,0.2-0.31,0.3-0.46c0.04-0.06,0.07-0.11,0.11-0.16c0.06-0.1,0.13-0.19,0.19-0.28 c-0.76-1.12-1.5-2.13-2.23-3.03C25.15,15.23,25.04,15.37,24.94,15.51z"
                opacity=".05"
              ></path>
              <path
                d="M21.67,20.27l-0.3,0.45c0.71,1.02,1.51,2.24,2.37,3.65c0.09-0.17,0.19-0.34,0.28-0.51C23.19,22.5,22.39,21.29,21.67,20.27 z M24.63,15.93c0.73,0.91,1.47,1.94,2.25,3.1c0.1-0.16,0.2-0.31,0.3-0.46c-0.77-1.14-1.52-2.16-2.24-3.06 C24.83,15.65,24.73,15.79,24.63,15.93z"
                opacity=".07"
              ></path>
            </svg>
          </div>
        </div>
        <div className="or" id="account-section">
          <h1>OR</h1>
        </div>
        <div className="sign-up" id="account-section">
          <h2>Create an Account</h2>
          <form className="sign-up-form" onSubmit={handleSubmit}>
            <label>
              <span className="signup-span">Name:</span>
              <input type="text" name="name" /*required*/ />
            </label>
            <label>
              <span className="signup-span">Email:</span>
              <input type="email" name="email" /*required*/ />
            </label>
            <label>
              <span className="signup-span">Mobile Number:</span>
              <input type="tel" name="mobile" /*required*/ />
            </label>
            <label>
              <span className="signup-span">Password:</span>
              <input
                type={passwordVisibility[1] ? "text" : "password"}
                name="password"
                id="password-input"
                ref={passwordRef}
                required
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="#000000"
                viewBox="0 0 256 256"
                className="eye eye--closed"
                onClick={() => togglePasswordVisibility(1)}
                style={{ display: passwordVisibility[1] ? "none" : "block" }}
              >
                <path d="M228,175a8,8,0,0,1-10.92-3l-19-33.2A123.23,123.23,0,0,1,162,155.46l5.87,35.22a8,8,0,0,1-6.58,9.21A8.4,8.4,0,0,1,160,200a8,8,0,0,1-7.88-6.69l-5.77-34.58a133.06,133.06,0,0,1-36.68,0l-5.77,34.58A8,8,0,0,1,96,200a8.4,8.4,0,0,1-1.32-.11,8,8,0,0,1-6.58-9.21L94,155.46a123.23,123.23,0,0,1-36.06-16.69L39,172A8,8,0,1,1,25.06,164l20-35a153.47,153.47,0,0,1-19.3-20A8,8,0,1,1,38.22,99c16.6,20.54,45.64,45,89.78,45s73.18-24.49,89.78-45A8,8,0,1,1,230.22,109a153.47,153.47,0,0,1-19.3,20l20,35A8,8,0,0,1,228,175Z"></path>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="#000000"
                viewBox="0 0 256 256"
                className="eye eye--open"
                onClick={() => togglePasswordVisibility(1)}
                style={{ display: passwordVisibility[1] ? "block" : "none" }}
              >
                <path d="M247.31,124.76c-.35-.79-8.82-19.58-27.65-38.41C194.57,61.26,162.88,48,128,48S61.43,61.26,36.34,86.35C17.51,105.18,9,124,8.69,124.76a8,8,0,0,0,0,6.5c.35.79,8.82,19.57,27.65,38.4C61.43,194.74,93.12,208,128,208s66.57-13.26,91.66-38.34c18.83-18.83,27.3-37.61,27.65-38.4A8,8,0,0,0,247.31,124.76ZM128,192c-30.78,0-57.67-11.19-79.93-33.25A133.47,133.47,0,0,1,25,128,133.33,133.33,0,0,1,48.07,97.25C70.33,75.19,97.22,64,128,64s57.67,11.19,79.93,33.25A133.46,133.46,0,0,1,231.05,128C223.84,141.46,192.43,192,128,192Zm0-112a48,48,0,1,0,48,48A48.05,48.05,0,0,0,128,80Zm0,80a32,32,0,1,1,32-32A32,32,0,0,1,128,160Z"></path>
              </svg>
            </label>
            <label>
              <span className="signup-span">Re-enter Password:</span>
              <input
                type={passwordVisibility[2] ? "text" : "password"}
                name="password"
                id="password-input"
                ref={confirmPasswordRef}
                required
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="#000000"
                viewBox="0 0 256 256"
                className="eye eye--closed"
                onClick={() => togglePasswordVisibility(2)}
                style={{ display: passwordVisibility[2] ? "none" : "block" }}
              >
                <path d="M228,175a8,8,0,0,1-10.92-3l-19-33.2A123.23,123.23,0,0,1,162,155.46l5.87,35.22a8,8,0,0,1-6.58,9.21A8.4,8.4,0,0,1,160,200a8,8,0,0,1-7.88-6.69l-5.77-34.58a133.06,133.06,0,0,1-36.68,0l-5.77,34.58A8,8,0,0,1,96,200a8.4,8.4,0,0,1-1.32-.11,8,8,0,0,1-6.58-9.21L94,155.46a123.23,123.23,0,0,1-36.06-16.69L39,172A8,8,0,1,1,25.06,164l20-35a153.47,153.47,0,0,1-19.3-20A8,8,0,1,1,38.22,99c16.6,20.54,45.64,45,89.78,45s73.18-24.49,89.78-45A8,8,0,1,1,230.22,109a153.47,153.47,0,0,1-19.3,20l20,35A8,8,0,0,1,228,175Z"></path>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="#000000"
                viewBox="0 0 256 256"
                className="eye eye--open"
                onClick={() => togglePasswordVisibility(2)}
                style={{ display: passwordVisibility[2] ? "block" : "none" }}
              >
                <path d="M247.31,124.76c-.35-.79-8.82-19.58-27.65-38.41C194.57,61.26,162.88,48,128,48S61.43,61.26,36.34,86.35C17.51,105.18,9,124,8.69,124.76a8,8,0,0,0,0,6.5c.35.79,8.82,19.57,27.65,38.4C61.43,194.74,93.12,208,128,208s66.57-13.26,91.66-38.34c18.83-18.83,27.3-37.61,27.65-38.4A8,8,0,0,0,247.31,124.76ZM128,192c-30.78,0-57.67-11.19-79.93-33.25A133.47,133.47,0,0,1,25,128,133.33,133.33,0,0,1,48.07,97.25C70.33,75.19,97.22,64,128,64s57.67,11.19,79.93,33.25A133.46,133.46,0,0,1,231.05,128C223.84,141.46,192.43,192,128,192Zm0-112a48,48,0,1,0,48,48A48.05,48.05,0,0,0,128,80Zm0,80a32,32,0,1,1,32-32A32,32,0,0,1,128,160Z"></path>
              </svg>
            </label>
            <button type="submit">Sign Up</button>
          </form>
        </div>
      </div>
      {/* <MouseFollower mouseTitle={mouseTitle} /> */}
    </div>
  );
};

export default DeleteAccountLogin;
