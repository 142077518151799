import React, { useState, useEffect, useContext } from "react";
import { GlobalStateContext } from "../components/GlobalVariable";
import "./BusinessName.css";
import { businessNameInstructions } from "./BusinessName_How_To";
import chaldeanChartImage from "../assets/Chaldean-1.png";
import ShareButtons from "../components/ShareButtons";
import Loader from "../components/Loader";
import MouseFollower from "../components/MouseFollower";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { all_images } from "../components/Related-Tools";
import {
  BUSINESS_FAQ_QUESTION_1,
  BUSINESS_FAQ_ANSWER_1,
  BUSINESS_FAQ_QUESTION_2,
  BUSINESS_FAQ_ANSWER_2,
  BUSINESS_FAQ_QUESTION_3,
  BUSINESS_FAQ_ANSWER_3,
  BUSINESS_FAQ_QUESTION_4,
  BUSINESS_FAQ_ANSWER_4,
  BUSINESS_FAQ_QUESTION_5,
  BUSINESS_FAQ_ANSWER_5,
  BUSINESS_FAQ_QUESTION_6,
  BUSINESS_FAQ_ANSWER_6,
  BUSINESS_FAQ_QUESTION_7,
  BUSINESS_FAQ_ANSWER_7,
  BUSINESS_FAQ_QUESTION_8,
  BUSINESS_FAQ_ANSWER_8,
  BUSINESS_FAQ_QUESTION_9,
  BUSINESS_FAQ_ANSWER_9,
  BUSINESS_FAQ_QUESTION_10,
  BUSINESS_FAQ_ANSWER_10,
} from "../components/Constants";
import exampleTextsBusinessNumber from "./BusinessName_Example_Text";
import ExampleUI from "../components/ExampleUI";

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const rawFaqs = [
    { question: BUSINESS_FAQ_QUESTION_1, answer: BUSINESS_FAQ_ANSWER_1 },
    { question: BUSINESS_FAQ_QUESTION_2, answer: BUSINESS_FAQ_ANSWER_2 },
    { question: BUSINESS_FAQ_QUESTION_3, answer: BUSINESS_FAQ_ANSWER_3 },
    { question: BUSINESS_FAQ_QUESTION_4, answer: BUSINESS_FAQ_ANSWER_4 },
    { question: BUSINESS_FAQ_QUESTION_5, answer: BUSINESS_FAQ_ANSWER_5 },
    { question: BUSINESS_FAQ_QUESTION_6, answer: BUSINESS_FAQ_ANSWER_6 },
    { question: BUSINESS_FAQ_QUESTION_7, answer: BUSINESS_FAQ_ANSWER_7 },
    { question: BUSINESS_FAQ_QUESTION_8, answer: BUSINESS_FAQ_ANSWER_8 },
    { question: BUSINESS_FAQ_QUESTION_9, answer: BUSINESS_FAQ_ANSWER_9 },
    { question: BUSINESS_FAQ_QUESTION_10, answer: BUSINESS_FAQ_ANSWER_10 },
  ];

  const faqs = rawFaqs.filter((faq) => faq.question);

  return (
    <div className="faq-container">
      {faqs.map((faq, index) => (
        <div key={index} className="faq-item">
          <div className="faq-question" onClick={() => toggleFAQ(index)}>
            <span className="arrow">{activeIndex === index ? "-" : "+"}</span>{" "}
            {faq.question}
          </div>
          {activeIndex === index && (
            <div className="faq-answer" onClick={() => toggleFAQ(index)}>
              {faq.answer}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

function BusinessName({ userData, setUserData }) {
  const { rateLimitCount, setRateLimitCount } = useContext(GlobalStateContext);
  const updateGlobalVariable = () => {
    //e.preventDefault();
    if (rateLimitCount > 0) {
      setRateLimitCount(rateLimitCount - 1);
    }
  };
  const [clicksRemaining, setClicksRemaining] = useState(5);
  const [, setTimerVisible] = useState(false);
  const [, setTimerSeconds] = useState(60);
  const url = window.location.href;
  const mouseTitle = "Find if your Business Name is Lucky for you";
  const browserTitle = "Lucky Business Name";
  const shareText =
    "Hi, I analyzed my business name to see if it attracts success. The results were surprising! Analyze yours at";
  const [BusinessName, setBusinessName] = useState("");
  const [result, setResult] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loading] = useState(false);
  const [error, setError] = useState("");
  const hasContent = result !== null;

  const handleBusinessNameChange = (event) => {
    const value = event.target.value;
    if (/^[a-zA-Z\s]*$/.test(value)) {
      setBusinessName(value);
    }
    setResult(null); // Clear previous API result
  };

  const startTimer = () => {
    setTimerVisible(true);

    // Timer to update seconds every second until the next minute starts
    const timerInterval = setInterval(() => {
      setTimerSeconds((prevSeconds) => {
        if (prevSeconds === 0) {
          clearInterval(timerInterval); // Clear interval when timer reaches 0
          setTimerSeconds(60); // Restart timer from 60 seconds
          setClicksRemaining(5); // Refresh clicks remaining to 5
          startTimer(); // Restart the timer
        }
        return prevSeconds - 1;
      });
    }, 1000);
  };

  const handleSubmit = async () => {
    updateGlobalVariable();
    // Clear previous errors
    setError(null);
    // Prevent submission if clicks remaining are exhausted
    if (clicksRemaining <= 0) {
      setError(
        "You have reached the maximum number of submissions. Please wait for the timer to reset."
      );
      return;
    }
    if (!BusinessName) {
      setError("Please enter the name of your Business");
      return;
    }

    const data = {
      buisnessName: BusinessName,
      system: "C",
      ownerDob: [],
    };

    setIsLoading(true);

    try {
      const response = await fetch(
        "https://api.scanandplaytambola.com/BusinessName",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      const result = await response.json();
      setResult(result);
    } catch (error) {
      console.error("Error fetching the API:", error);
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 5000);

      // Decrement clicks remaining
      setClicksRemaining((prev) => prev - 1);

      // Start timer only on the first submit
      if (clicksRemaining === 5) {
        startTimer();
      }
    }
  };

  useEffect(() => {
    if (userData) {
      setBusinessName(userData.businessName || "");
    }
  }, [userData]);

  useEffect(() => {
    document.title = browserTitle;
  }, []);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true, // Optionally enable/disable arrows if needed
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 840,
        settings: {
          slidesToShow: 1,
          dots: false,
        },
      },
      {
        breakpoint: 485,
        settings: {
          slidesToShow: 1,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  const imageIndexes = [0, 1, 5, 20, 9, 3, 13, 22, 15, 7];

  return (
    <div className="BusinessName">
      <Helmet>
        <title>Lucky Business Name</title>
        <meta
          name="description"
          content="Choose a Lucky Business Name to ensure your business vibrates with positive energy, leading to success and growth."
        />
      </Helmet>
      <div className="content">
        <br></br>
        <div className="input-and-result-window">
          <div className="special">
            <h1>Lucky Business Name Calculator</h1>
            <div>
              <p className="label">Enter your Business name:</p>
              <input
                type="text"
                value={BusinessName}
                onChange={handleBusinessNameChange}
                placeholder="Business Name"
              />
            </div>
            {error && <p className="error-message">{error}</p>}
            <button
              onClick={handleSubmit}
              disabled={loading || clicksRemaining <= 0}
            >
              Submit
            </button>
          </div>
          <div className="special result-window">
            <h2>Result</h2>
            {isLoading && <Loader />}
            <p>
              Business Number:{" "}
              <b>{!isLoading && result ? result.businessName_Number : ""}</b>
            </p>
            {!isLoading && result && result.businessInterpretation_strength && (
              <p>
                <strong>Interpretation:</strong>{" "}
                {result.businessInterpretation_strength}
              </p>
            )}
            {!isLoading && result && result.businessInterpretation_weekness && (
              <p>
                <strong>Weakness:</strong>{" "}
                {result.businessInterpretation_weekness}
              </p>
            )}
          </div>
        </div>
        <div
          className="left-align-content"
          id="breadcrumbs"
          style={{ display: "flex", alignItems: "center" }}
        >
          <p className="share-line" style={{ margin: 0 }}>
            Please feel free to share with your Friends and Family :
          </p>
          <ShareButtons url={url} shareText={shareText} />
        </div>
        <div className="left-align-content">
          <h2>How to calculate Business Number :</h2>
          <div
            dangerouslySetInnerHTML={{
              __html: businessNameInstructions.howToCalculate,
            }}
          />
          <img
            className="center-image-chaldean"
            src={chaldeanChartImage}
            alt="Chandelean Chart"
          />
        </div>
        <div className="related-tools-div">
          <h1 className="h1-center">Our Other Tools</h1>
          <div className="carousel-container">
            <Slider {...settings}>
              {imageIndexes.map((index) => (
                <div key={index}>
                  <Link to={all_images[index].link}>
                    <img
                      src={all_images[index].imageSrc}
                      alt={all_images[index].alt}
                      className="carousel-image"
                    />
                  </Link>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        <ExampleUI texts={exampleTextsBusinessNumber} />
        <div className="sharebuttons">
          <ShareButtons url={url} shareText={shareText} />
        </div>
        <MouseFollower mouseTitle={mouseTitle} />
        <div className="faq-section">
          <h2 className="faq-heading">Frequently Asked Questions</h2>
          <Faq />
        </div>
      </div>
    </div>
  );
}

export default BusinessName;
