import React, { useState, useEffect, useContext } from "react";
import "./BestDayToFast.css";
import planetAndDays from "../assets/Planet-and-Days-1.png";
import DateOfBirthSelector from "../components/DateOfBirthSelector";
import { fastingDayInstructions } from "./BestDayToFast_How_To";
import { GlobalStateContext } from "../components/GlobalVariable";
import ShareButtons from "../components/ShareButtons";
import Loader from "../components/Loader";
import MouseFollower from "../components/MouseFollower";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { all_images } from "../components/Related-Tools";
import {
  FASTING_FAQ_QUESTION_1,
  FASTING_FAQ_ANSWER_1,
  FASTING_FAQ_QUESTION_2,
  FASTING_FAQ_ANSWER_2,
  FASTING_FAQ_QUESTION_3,
  FASTING_FAQ_ANSWER_3,
  FASTING_FAQ_QUESTION_4,
  FASTING_FAQ_ANSWER_4,
  FASTING_FAQ_QUESTION_5,
  FASTING_FAQ_ANSWER_5,
  FASTING_FAQ_QUESTION_6,
  FASTING_FAQ_ANSWER_6,
  FASTING_FAQ_QUESTION_7,
  FASTING_FAQ_ANSWER_7,
  FASTING_FAQ_QUESTION_8,
  FASTING_FAQ_ANSWER_8,
  FASTING_FAQ_QUESTION_9,
  FASTING_FAQ_ANSWER_9,
  FASTING_FAQ_QUESTION_10,
  FASTING_FAQ_ANSWER_10,
} from "../components/Constants";
import exampleTextsFastingDay from "./BestDayToFast_Example_Text";
import ExampleUI from "../components/ExampleUI";

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const rawFaqs = [
    { question: FASTING_FAQ_QUESTION_1, answer: FASTING_FAQ_ANSWER_1 },
    { question: FASTING_FAQ_QUESTION_2, answer: FASTING_FAQ_ANSWER_2 },
    { question: FASTING_FAQ_QUESTION_3, answer: FASTING_FAQ_ANSWER_3 },
    { question: FASTING_FAQ_QUESTION_4, answer: FASTING_FAQ_ANSWER_4 },
    { question: FASTING_FAQ_QUESTION_5, answer: FASTING_FAQ_ANSWER_5 },
    { question: FASTING_FAQ_QUESTION_6, answer: FASTING_FAQ_ANSWER_6 },
    { question: FASTING_FAQ_QUESTION_7, answer: FASTING_FAQ_ANSWER_7 },
    { question: FASTING_FAQ_QUESTION_8, answer: FASTING_FAQ_ANSWER_8 },
    { question: FASTING_FAQ_QUESTION_9, answer: FASTING_FAQ_ANSWER_9 },
    { question: FASTING_FAQ_QUESTION_10, answer: FASTING_FAQ_ANSWER_10 },
  ];

  const faqs = rawFaqs.filter((faq) => faq.question);

  return (
    <div className="faq-container">
      {faqs.map((faq, index) => (
        <div key={index} className="faq-item">
          <div className="faq-question" onClick={() => toggleFAQ(index)}>
            <span className="arrow">{activeIndex === index ? "-" : "+"}</span>{" "}
            {faq.question}
          </div>
          {activeIndex === index && (
            <div className="faq-answer" onClick={() => toggleFAQ(index)}>
              {faq.answer}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

function BestDayToFast({ userData, setUserData }) {
  const {
    day,
    setDay,
    month,
    setMonth,
    year,
    setYear,
    rateLimitCount,
    setRateLimitCount,
  } = useContext(GlobalStateContext);
  const updateGlobalVariable = () => {
    //e.preventDefault();
    if (rateLimitCount > 0) {
      setRateLimitCount(rateLimitCount - 1);
    }
  };

  const url = window.location.href;
  const mouseTitle = "When is the best Day to Fast?";
  const browserTitle = "Fasting Day";
  const [, setError] = useState(null);
  const [daysInMonth, setDaysInMonth] = useState(31);
  const [fastingDay, setFastingDay] = useState("");
  const [disclaimer, setDisclaimer] = useState("");
  const [apiResult, setApiResult] = useState(null);
  const [clicksRemaining, setClicksRemaining] = useState(5);
  const [, setTimerVisible] = useState(false);
  const [, setTimerSeconds] = useState(60); // Initial timer countdown seconds
  const [loading, setLoading] = useState(false);
  const shareText =
    "Did you know that fasting days are related to Numerology? Find out yours and the benefits at";

  const hasContent = fastingDay !== null;

  useEffect(() => {
    const updateDaysInMonth = (month, year) => {
      const days = new Date(year, month, 0).getDate();
      setDaysInMonth(days);
      if (day > days) {
        alert("Invalid date, re-check and enter again");
        setDay(1);
      }
    };
    updateDaysInMonth(month, year);
  }, [month, year, day]);

  const handleDayChange = (event) => {
    setDay(parseInt(event.target.value, 10));
    setApiResult(null); // Clear previous API result
  };
  const handleMonthChange = (event) => {
    setMonth(parseInt(event.target.value, 10));
    setApiResult(null); // Clear previous API result
  };
  const handleYearChange = (event) => {
    setYear(parseInt(event.target.value, 10));
    setApiResult(null); // Clear previous API result
  };

  const startTimer = () => {
    setTimerVisible(true);

    // Timer to update seconds every second until the next minute starts
    const timerInterval = setInterval(() => {
      setTimerSeconds((prevSeconds) => {
        if (prevSeconds === 0) {
          clearInterval(timerInterval); // Clear interval when timer reaches 0
          setTimerSeconds(60); // Restart timer from 60 seconds
          setClicksRemaining(5); // Refresh clicks remaining to 5
          startTimer(); // Restart the timer
        }
        return prevSeconds - 1;
      });
    }, 1000);
  };

  const handleSubmit = async () => {
    updateGlobalVariable();
    // Clear previous errors
    setError(null);

    // Prevent submission if clicks remaining are exhausted
    if (clicksRemaining <= 0) {
      setError(
        "You have reached the maximum number of submissions. Please wait for the timer to reset."
      );
      return;
    }

    setLoading(true);
    const startTime = Date.now(); // Record the start time

    const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;
    const apiUrl = "https://api.scanandplaytambola.com/fastingDay";

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ dateOfBirth: formattedDate }),
      });

      if (!response.ok) throw new Error("Network response was not ok");

      const data = await response.json();
      setApiResult(data);

      if (data.fastDay && data.fastDay.length > 0) {
        const fullMessage = `${data.fastDay[0]}`;
        setFastingDay(fullMessage);
      } else {
        setFastingDay("No fasting recommendation found.");
      }

      setDisclaimer(data.disclaimer);

      // Update user data with edited values
      setUserData({
        ...userData,
        day: day,
        month: month,
        year: year,
      });
    } catch (error) {
      console.error("There was a problem with your fetch operation:", error);
    } finally {
      const endTime = Date.now();
      const elapsedTime = endTime - startTime;
      const remainingTime = 5000 - elapsedTime; // Ensure a minimum of 5 seconds

      if (remainingTime > 0) {
        setTimeout(() => {
          setLoading(false);
        }, remainingTime);
      } else {
        setLoading(false);
      }

      // Decrement clicks remaining and start the timer if it's the first submit
      setClicksRemaining((prev) => prev - 1);
      if (clicksRemaining === 5) {
        startTimer();
      }
    }
  };

  useEffect(() => {
    document.title = browserTitle; // Set document title on component mount
  }, []); // Empty dependency array to run only once

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true, // Optionally enable/disable arrows if needed
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 840,
        settings: {
          slidesToShow: 1,
          dots: false,
        },
      },
      {
        breakpoint: 485,
        settings: {
          slidesToShow: 1,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  const imageIndexes = [0, 1, 19, 17, 4, 12, 5, 22, 21, 10, 2];

  return (
    <div className="BestDayToFast">
      <Helmet>
        <title>Best Day to Fast</title>
        <meta
          name="description"
          content="Identify your optimal fasting days to align with cosmic energies, promoting health, spiritual growth, and overall well-being."
        />
      </Helmet>
      <div className="content">
        <div className="input-and-result-window">
          <div className="special">
            <h1>Fasting Day Calculator</h1>
            <DateOfBirthSelector
              day={day}
              month={month}
              year={year}
              daysInMonth={daysInMonth}
              handleDayChange={handleDayChange}
              handleMonthChange={handleMonthChange}
              handleYearChange={handleYearChange}
            />
            <button
              onClick={handleSubmit}
              disabled={loading || clicksRemaining <= 0}
            >
              Submit
            </button>
          </div>
          <div className="special result-window">
            <h2>Result</h2>
            {loading && <Loader />}
            <p>
              <strong>As per your Date of Birth you should: </strong>
              {loading ? "" : fastingDay}
            </p>
          </div>
        </div>
        {/* Loader component is displayed when loading is true */}
        {loading && <Loader />}
        <div>
          {disclaimer && !loading && (
            <div className="left-align-content" id="breadcrumbs">
              <h2>Disclaimer:</h2>
              <p>{disclaimer}</p>
            </div>
          )}
        </div>
        <div
          className="left-align-content"
          id="breadcrumbs"
          style={{ display: "flex", alignItems: "center" }}
        >
          <p className="share-line" style={{ margin: 0 }}>
            Please feel free to share with your Friends and Family :
          </p>
          <ShareButtons url={url} shareText={shareText} />
        </div>
        <div className="left-align-content">
          <h2>Fasting Day Calculation : </h2>
          <div
            dangerouslySetInnerHTML={{
              __html: fastingDayInstructions.howToCalculate,
            }}
          />
          <img
            src={planetAndDays}
            alt="Planet and Day Chart"
            className="center-image-planet-day"
          />
        </div>
        <div className="related-tools-div">
          <h1 className="h1-center">Our Other Tools</h1>
          <div className="carousel-container">
            <Slider {...settings}>
              {imageIndexes.map((index) => (
                <div key={index}>
                  <Link to={all_images[index].link}>
                    <img
                      src={all_images[index].imageSrc}
                      alt={all_images[index].alt}
                      className="carousel-image"
                    />
                  </Link>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        <ExampleUI texts={exampleTextsFastingDay} />
        <div className="sharebuttons">
          <ShareButtons url={url} shareText={shareText} />
        </div>
        <MouseFollower mouseTitle={mouseTitle} />{" "}
        {/* Pass mouseTitle as a prop */}
        <div className="faq-section">
          <h2 className="faq-heading">Frequently Asked Questions</h2>
          <Faq />
        </div>
      </div>
    </div>
  );
}

export default BestDayToFast;
