import React, { useState, useEffect, useContext } from "react";
import { GlobalStateContext } from "../components/GlobalVariable";
import "./BiorhythmChart.css";
import { dailyBiorhythmInstructions } from "./DailyBiorhythm_How_To";
import dailyFormula from "../assets/DailyBiorhythm-1.png";
import DateOfBirthSelector from "../components/DateOfBirthSelector";
import ShareButtons from "../components/ShareButtons";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import Loader from "../components/Loader";
import MouseFollower from "../components/MouseFollower";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { all_images } from "../components/Related-Tools";
import {
  DAILY_BIORHYTHM_FAQ_QUESTION_1,
  DAILY_BIORHYTHM_FAQ_ANSWER_1,
  DAILY_BIORHYTHM_FAQ_QUESTION_2,
  DAILY_BIORHYTHM_FAQ_ANSWER_2,
  DAILY_BIORHYTHM_FAQ_QUESTION_3,
  DAILY_BIORHYTHM_FAQ_ANSWER_3,
  DAILY_BIORHYTHM_FAQ_QUESTION_4,
  DAILY_BIORHYTHM_FAQ_ANSWER_4,
  DAILY_BIORHYTHM_FAQ_QUESTION_5,
  DAILY_BIORHYTHM_FAQ_ANSWER_5,
  DAILY_BIORHYTHM_FAQ_QUESTION_6,
  DAILY_BIORHYTHM_FAQ_ANSWER_6,
  DAILY_BIORHYTHM_FAQ_QUESTION_7,
  DAILY_BIORHYTHM_FAQ_ANSWER_7,
  DAILY_BIORHYTHM_FAQ_QUESTION_8,
  DAILY_BIORHYTHM_FAQ_ANSWER_8,
  DAILY_BIORHYTHM_FAQ_QUESTION_9,
  DAILY_BIORHYTHM_FAQ_ANSWER_9,
  DAILY_BIORHYTHM_FAQ_QUESTION_10,
  DAILY_BIORHYTHM_FAQ_ANSWER_10,
} from "../components/Constants";

const getCurrentDate = () => {
  const date = new Date();
  return {
    day: date.getDate(),
    month: date.getMonth() + 1, // getMonth() returns 0-based month, so we add 1
    year: date.getFullYear(),
  };
};

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const rawFaqs = [
    {
      question: DAILY_BIORHYTHM_FAQ_QUESTION_1,
      answer: DAILY_BIORHYTHM_FAQ_ANSWER_1,
    },
    {
      question: DAILY_BIORHYTHM_FAQ_QUESTION_2,
      answer: DAILY_BIORHYTHM_FAQ_ANSWER_2,
    },
    {
      question: DAILY_BIORHYTHM_FAQ_QUESTION_3,
      answer: DAILY_BIORHYTHM_FAQ_ANSWER_3,
    },
    {
      question: DAILY_BIORHYTHM_FAQ_QUESTION_4,
      answer: DAILY_BIORHYTHM_FAQ_ANSWER_4,
    },
    {
      question: DAILY_BIORHYTHM_FAQ_QUESTION_5,
      answer: DAILY_BIORHYTHM_FAQ_ANSWER_5,
    },
    {
      question: DAILY_BIORHYTHM_FAQ_QUESTION_6,
      answer: DAILY_BIORHYTHM_FAQ_ANSWER_6,
    },
    {
      question: DAILY_BIORHYTHM_FAQ_QUESTION_7,
      answer: DAILY_BIORHYTHM_FAQ_ANSWER_7,
    },
    {
      question: DAILY_BIORHYTHM_FAQ_QUESTION_8,
      answer: DAILY_BIORHYTHM_FAQ_ANSWER_8,
    },
    {
      question: DAILY_BIORHYTHM_FAQ_QUESTION_9,
      answer: DAILY_BIORHYTHM_FAQ_ANSWER_9,
    },
    {
      question: DAILY_BIORHYTHM_FAQ_QUESTION_10,
      answer: DAILY_BIORHYTHM_FAQ_ANSWER_10,
    },
  ];

  const faqs = rawFaqs.filter((faq) => faq.question);

  return (
    <div className="faq-container">
      {faqs.map((faq, index) => (
        <div key={index} className="faq-item">
          <div className="faq-question" onClick={() => toggleFAQ(index)}>
            <span className="arrow">{activeIndex === index ? "-" : "+"}</span>{" "}
            {faq.question}
          </div>
          {activeIndex === index && (
            <div className="faq-answer" onClick={() => toggleFAQ(index)}>
              {faq.answer}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

function DailyBiorhythmChart({ userData, setUserData }) {
  const {
    day,
    setDay,
    month,
    setMonth,
    year,
    setYear,
    rateLimitCount,
    setRateLimitCount,
  } = useContext(GlobalStateContext);
  const updateGlobalVariable = () => {
    //e.preventDefault();
    if (rateLimitCount > 0) {
      setRateLimitCount(rateLimitCount - 1);
    }
  };
  const [clicksRemaining, setClicksRemaining] = useState(5);
  const [, setTimerVisible] = useState(false);
  const [, setTimerSeconds] = useState(60);
  const url = window.location.href;
  const mouseTitle = "Find daily Biorhythm Chart";
  const browserTitle = "Daily Biorhythm Chart";
  const shareText = "This tool is so right about my day. Check yours at";
  const [, setParticularDay] = useState(getCurrentDate());
  const [tempParticularDay, setTempParticularDay] = useState(getCurrentDate());
  const [daysInMonth, setDaysInMonth] = useState(31);
  const [biorhythmData, setBiorhythmData] = useState({});
  const [loading, setLoading] = useState(false);
  const [, setSubmitted] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    function updateDaysInMonth(month, year) {
      const days = new Date(year, month, 0).getDate();
      setDaysInMonth(days);
      if (day > days) {
        alert("Invalid date, re-check and enter again");
        setDay(1);
      }
    }
    updateDaysInMonth(month, year);
  }, [month, year, day]);

  const handleDayChange = (event) => {
    setDay(parseInt(event.target.value, 10));
    setBiorhythmData({});
    setSubmitted(false);
  };

  const handleMonthChange = (event) => {
    setMonth(parseInt(event.target.value, 10));
    setBiorhythmData({});
    setSubmitted(false);
  };

  const handleYearChange = (event) => {
    setYear(parseInt(event.target.value, 10));
    setBiorhythmData({});
    setSubmitted(false);
  };

  const handleTempParticularDayChange = (field, value) => {
    setTempParticularDay((prev) => ({ ...prev, [field]: parseInt(value, 10) }));
    setBiorhythmData({});
    setSubmitted(false);
  };

  const startTimer = () => {
    setTimerVisible(true);

    // Timer to update seconds every second until the next minute starts
    const timerInterval = setInterval(() => {
      setTimerSeconds((prevSeconds) => {
        if (prevSeconds === 0) {
          clearInterval(timerInterval); // Clear interval when timer reaches 0
          setTimerSeconds(60); // Restart timer from 60 seconds
          setClicksRemaining(5); // Refresh clicks remaining to 5
          startTimer(); // Restart the timer
        }
        return prevSeconds - 1;
      });
    }, 1000);
  };

  const handlePredictionSubmit = async () => {
    updateGlobalVariable();
    // Clear previous errors
    setError(null);
    // Prevent submission if clicks remaining are exhausted
    if (clicksRemaining <= 0) {
      setError(
        "You have reached the maximum number of submissions. Please wait for the timer to reset."
      );
      return;
    }
    setLoading(true);
    setError(null);

    setParticularDay(tempParticularDay);

    if (
      !tempParticularDay.day ||
      !tempParticularDay.month ||
      !tempParticularDay.year
    ) {
      setError("Please select a valid Particular Day.");
      setLoading(false);
      return;
    }

    console.log("Submitting data:", {
      dateOfBirth: `${day}-${month}-${year}`,
      particularDay: `${tempParticularDay.day}-${tempParticularDay.month}-${tempParticularDay.year}`,
    });

    const apiUrl =
      "https://api.scanandplaytambola.com/monthlyBiorythmChartparticularDay";

    try {
      const apiPromise = fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          dateOfBirth: `${day}-${month}-${year}`,
          particularDay: `${tempParticularDay.day}-${tempParticularDay.month}-${tempParticularDay.year}`,
        }),
      }).then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch biorhythm data");
        }
        return response.json();
      });

      const delayPromise = new Promise((resolve) => setTimeout(resolve, 5000));

      const [data] = await Promise.all([apiPromise, delayPromise]);

      console.log("API response data:", data);

      if (!data || !data.monthly || data.monthly.length === 0) {
        throw new Error("Invalid response format");
      }

      setBiorhythmData(data.monthly[0]);

      // Update userData
      setUserData({
        ...userData,
        day: day,
        month: month,
        year: year,
        particularDay: tempParticularDay,
      });

      setSubmitted(true); // Mark form as submitted
    } catch (error) {
      console.error("Error fetching biorhythm data:", error);
      setError("Error fetching biorhythm data. Please try again later.");
    } finally {
      // Decrement clicks remaining
      setClicksRemaining((prev) => prev - 1);

      // Start timer only on the first submit
      if (clicksRemaining === 5) {
        startTimer();
      }

      setLoading(false);
    }
  };

  const chartData = {
    labels: ["Emotional", "Intellectual", "Physical"],
    datasets: [
      {
        data: [
          parseFloat(biorhythmData.emotional || 0),
          parseFloat(biorhythmData.intellectual || 0),
          parseFloat(biorhythmData.physical || 0),
        ],
        backgroundColor: [
          "rgba(255, 99, 132, 0.8)",
          "rgba(54, 162, 235, 0.8)",
          "rgba(75, 75, 75, 0.8)",
        ],
      },
    ],
  };

  useEffect(() => {
    document.title = browserTitle;
  }, []);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true, // Optionally enable/disable arrows if needed
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 840,
        settings: {
          slidesToShow: 1,
          dots: false,
        },
      },
      {
        breakpoint: 485,
        settings: {
          slidesToShow: 1,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  const imageIndexes = [0, 1, 19, 17, 13, 5, 2, 22, 12, 21, 15, 24];

  return (
    <div className="BiorhythmChart">
      <Helmet>
        <title>Daily Biorhythm Chart</title>
        <meta
          name="description"
          content="Use the Biorhythm Chart to track your physical, emotional, and intellectual cycles, optimizing your daily activities and well-being."
        />
      </Helmet>
      <div className="content">
        <div className="special">
          <h1>Daily Biorhythm Chart</h1>
          <div>
            <DateOfBirthSelector
              day={day}
              month={month}
              year={year}
              daysInMonth={daysInMonth}
              handleDayChange={handleDayChange}
              handleMonthChange={handleMonthChange}
              handleYearChange={handleYearChange}
            />
          </div>
          <div>
            <p className="label">Select the Particular Day:</p>
            <select
              value={tempParticularDay.day}
              onChange={(e) =>
                handleTempParticularDayChange("day", e.target.value)
              }
            >
              <option value="" disabled selected>
                Day
              </option>
              {Array.from({ length: 31 }, (_, i) => (
                <option key={i + 1} value={i + 1}>
                  {i + 1}
                </option>
              ))}
            </select>
            <select
              value={tempParticularDay.month}
              onChange={(e) =>
                handleTempParticularDayChange("month", e.target.value)
              }
            >
              <option value="" disabled selected>
                Month
              </option>
              {Array.from({ length: 12 }, (_, i) => (
                <option key={i + 1} value={i + 1}>
                  {new Date(0, i + 1, 0).toLocaleString("en", {
                    month: "long",
                  })}
                </option>
              ))}
            </select>
            <select
              value={tempParticularDay.year}
              onChange={(e) =>
                handleTempParticularDayChange("year", e.target.value)
              }
            >
              <option value="" disabled selected>
                Year
              </option>
              {Array.from({ length: 2100 - 2020 }, (_, i) => (
                <option key={i + 2020} value={i + 2020}>
                  {i + 2020}
                </option>
              ))}
            </select>
          </div>
          <button
            onClick={handlePredictionSubmit}
            disabled={loading || clicksRemaining <= 0}
          >
            Submit
          </button>
        </div>
        <div></div>
        {loading && <Loader />}
        {error && <div className="error-message">{error}</div>}
        {!loading && Object.keys(biorhythmData).length > 0 && (
          <div className="chart-container">
            <Bar
              data={chartData}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: false,
                  },
                },
              }}
            />
          </div>
        )}
        <div
          className="left-align-content"
          id="breadcrumbs"
          style={{ display: "flex", alignItems: "center" }}
        >
          <p className="share-line" style={{ margin: 0 }}>
            Please feel free to share with your Friends and Family :
          </p>
          <ShareButtons url={url} shareText={shareText} />
        </div>
        <div className="left-align-content">
          <h2>Daily Biorhythm Calculation</h2>
          <div
            dangerouslySetInnerHTML={{
              __html: dailyBiorhythmInstructions.howToCalculate,
            }}
          />
          <img
            src={dailyFormula}
            alt="Daily Biorhythm Formula"
            className="center-image"
          />
        </div>
        <div className="sharebuttons">
          <ShareButtons url={url} shareText={shareText} />
        </div>
        <div className="related-tools-div">
          <h1 className="h1-center">Our Other Tools</h1>
          <div className="carousel-container">
            <Slider {...settings}>
              {imageIndexes.map((index) => (
                <div key={index}>
                  <Link to={all_images[index].link}>
                    <img
                      src={all_images[index].imageSrc}
                      alt={all_images[index].alt}
                      className="carousel-image"
                    />
                  </Link>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        <MouseFollower mouseTitle={mouseTitle} />{" "}
        {/* Pass mouseTitle as a prop */}
        <div className="faq-section">
          <h2 className="faq-heading">Frequently Asked Questions</h2>
          <Faq />
        </div>
      </div>
    </div>
  );
}

export default DailyBiorhythmChart;
