import React from "react";
import "./ModalWindows.css";

const DeleteAccount = ({ isOpen, onClose }) => {
  if (!isOpen) return null; // Don't render if modal is not open

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Account Deletion Request</h2>
        <p>
          Your account deletion request has been taken into consideration and
          will be deleted from our system within 30 days.
        </p>
        <p>
          If you need to revert this action, please drop an email to{" "}
          <strong>revokeDeletion@elitenumerology.com</strong>.
        </p>
        <button className="close-button" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default DeleteAccount;
