import React, { useEffect } from "react";
import MouseFollower from "../components/MouseFollower";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

const mouseTitle = "Forgot Password?";
const browserTitle = "Forgot Password?";

const ForgotPassword = () => {
  useEffect(() => {
    document.title = browserTitle; // Set document title on component mount

    // Adding event listeners to form elements in useEffect
    const emailInputs = document.querySelectorAll('input[type="email"]');
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    emailInputs.forEach((input) => {
      input.addEventListener("input", function () {
        if (!emailRegex.test(this.value)) {
          this.setCustomValidity(
            "Please enter a valid email id. For example - username@domain.com"
          );
        } else {
          this.setCustomValidity("");
        }
      });
      input.addEventListener("invalid", function () {
        this.setCustomValidity(
          "Please enter a valid email id. For example - username@domain.com"
        );
      });
    });

    // Cleanup function to remove event listeners
    return () => {
      emailInputs.forEach((input) => {
        input.removeEventListener("input", null);
        input.removeEventListener("invalid", null);
      });
    };
  }, []); // Empty dependency array to run only once

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent default form submission

    const form = event.target;
    if (form.checkValidity()) {
      // If the form is valid, navigate to the next route
      navigate("/verify-otp");
    }
  };

  return (
    <div className="forgotPassword">
      <Helmet>
        <title>Elite Numerology - Forgot Password</title>
      </Helmet>
      <div className="signup-and-login">
        <div className="sign-up">
          <h1>Forgot Password?</h1>
          <form className="sign-up-form" onSubmit={handleSubmit}>
            <label>
              <input
                type="email"
                placeholder="Enter your email ID"
                name="email"
                required
              />
            </label>
            <button type="submit" className="reset-password">
              Get OTP
            </button>
          </form>
        </div>
      </div>
      <MouseFollower mouseTitle={mouseTitle} />
    </div>
  );
};

export default ForgotPassword;
