import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDGgPFgFS4L6P-X2hIiANjNjAZ0TP9bKNM",
  authDomain: "elite-numerology.firebaseapp.com",
  projectId: "elite-numerology",
  storageBucket: "elite-numerology.appspot.com",
  messagingSenderId: "924249551595",
  appId: "1:924249551595:web:943ef65aa0c2c16d5844ae",
  measurementId: "G-55YMPXDN7S",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();

export { auth, googleProvider, facebookProvider };
export default app;
