import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom
import MouseFollower from "../components/MouseFollower";
import { Helmet } from "react-helmet";

const mouseTitle = "Verify OTP";
const browserTitle = "Verify OTP";

const VerifyOTP = () => {
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");

  // Regex for alphanumeric characters
  const otpRegex = /^[a-zA-Z0-9]*$/;
  const navigate = useNavigate(); // Initialize useNavigate hook

  const handleOtpChange = (e) => {
    const value = e.target.value;
    if (otpRegex.test(value)) {
      setOtp(value);
      setError(""); // Clear error if OTP is valid
    } else {
      setError("OTP can only contain alphanumeric characters");
    }
  };

  const handleResetPasswordClick = (e) => {
    e.preventDefault(); // Prevent default action of the button
    if (!otp.trim()) {
      setError("Please enter OTP sent to your registered email");
    } else if (!otpRegex.test(otp)) {
      setError("OTP can only contain alphanumeric characters");
    } else {
      setError(""); // Clear error if OTP is valid
      navigate("/reset-password"); // Navigate to the /reset-password page
    }
  };

  const handleKeyPress = (e) => {
    // Allow only alphanumeric characters
    if (!/[a-zA-Z0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <div className="verifyOTP">
      <Helmet>
        <title>Elite Numerology - Sign Up and Login</title>
      </Helmet>
      <div className="signup-and-login">
        <div className="sign-up">
          <h1>Verify OTP</h1>
          <form className="sign-up-form">
            <label>
              <input
                type="text"
                placeholder="Enter OTP"
                value={otp}
                onChange={handleOtpChange}
                onKeyPress={handleKeyPress}
                name="otp"
              />
              {error && (
                <p className="password-error password-error--otp">{error}</p>
              )}
            </label>

            <button
              className="reset-password"
              onClick={handleResetPasswordClick}
            >
              Reset Password
            </button>
          </form>
        </div>
      </div>
      <MouseFollower mouseTitle={mouseTitle} />
    </div>
  );
};

export default VerifyOTP;
