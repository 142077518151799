import React from "react";

const DateOfBirthSelector = ({
  day,
  month,
  year,
  daysInMonth,
  handleDayChange,
  handleMonthChange,
  handleYearChange,
}) => {
  return (
    <div>
      <p className="label">Select your Date of Birth:</p>
      <select value={day} onChange={handleDayChange}>
        <option value="" disabled selected>
          Day
        </option>
        {Array.from({ length: daysInMonth }, (_, i) => (
          <option key={i + 1} value={i + 1}>
            {i + 1}
          </option>
        ))}
      </select>

      <select value={month} onChange={handleMonthChange}>
        <option value="" disabled selected>
          Month
        </option>
        {Array.from({ length: 12 }, (_, i) => (
          <option key={i + 1} value={i + 1}>
            {new Date(0, i + 1, 0).toLocaleString("en", {
              month: "long",
            })}
          </option>
        ))}
      </select>

      <select value={year} onChange={handleYearChange}>
        <option value="" disabled selected>
          Year
        </option>
        {Array.from({ length: 2025 - 1900 }, (_, i) => (
          <option key={i + 1900} value={i + 1900}>
            {i + 1900}
          </option>
        ))}
      </select>
    </div>
  );
};

export default DateOfBirthSelector;
